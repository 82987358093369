import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22693d16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alternative-container--alternative-option-label" }
const _hoisted_2 = { class: "eliminate-option-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EliminateOptionIcon = _resolveComponent("EliminateOptionIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["alternative-container", {
      'alternative-option-label__selected': (_ctx.isSelected || _ctx.selected),
      'alternative-option-label__correct': _ctx.correct,
      'alternative-option-label__wrong': _ctx.wrong,
      'alternative-option-label__horizontal': _ctx.horizontalMode,
      [`alternative-option-label__${_ctx.questionType}`]: _ctx.questionType,
      'alternative-option-label__not-selectable': _ctx.selected || _ctx.correct || _ctx.wrong ? true : _ctx.notSelectable,
      'alternative-option-label__removed': _ctx.discarded,
      'alternative-option-label__uninvolved': (_ctx.showAnswer && (!_ctx.selected && !_ctx.correct && !_ctx.wrong))
    }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleAlternativeSelection(_ctx.id))),
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleHover(true))),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleHover(false)))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.setAlternativeOptionLabels(_ctx.$parent, _ctx.index)), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["alternative-container--label", { label__horizontal: _ctx.horizontalMode }])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.content), 1)
        ], true)
      ], 2),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createVNode(_component_EliminateOptionIcon, {
          stroke: _ctx.DefaultTheme().colors.secondaryGray,
          class: "eliminate-option eliminate-option-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleAlternativeRemoval(_ctx.id, $event)))
        }, null, 8, ["stroke"]), [
          [_vShow, !_ctx.isSelected && _ctx.showRemoveAlternativeIcon && !(_ctx.notSelectable || _ctx.selected || _ctx.correct || _ctx.wrong)]
        ])
      ])
    ], 34), [
      [_vShow, !_ctx.displaySelectedOnly || (_ctx.displaySelectedOnly && (_ctx.isSelected || _ctx.selected))]
    ]),
    (_ctx.showAnswer && _ctx.showAlternativeSpanText)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["instant-feedback-span", {
      'feedback-span__wrong': _ctx.wrong,
      'feedback-span__correct': _ctx.correct,
    }])
        }, _toDisplayString(_ctx.getAlternativeSpanText()), 3))
      : _createCommentVNode("", true)
  ], 64))
}