import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-358ddc98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-content" }
const _hoisted_2 = {
  key: 0,
  class: "body-description"
}
const _hoisted_3 = {
  key: 1,
  class: "body-description sub-text"
}
const _hoisted_4 = {
  key: 2,
  class: "body-description sub-text"
}
const _hoisted_5 = {
  key: 3,
  class: "body-description"
}
const _hoisted_6 = {
  key: 4,
  class: "body-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PazzeiButton = _resolveComponent("PazzeiButton")!
  const _component_PazzeiModal = _resolveComponent("PazzeiModal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_PazzeiModal, {
        key: 0,
        onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close-modal-limit-time'))),
        context: _ctx.context
      }, _createSlots({
        description: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.showLoader)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Ei, atenção para o tempo! "))
              : _createCommentVNode("", true),
            (!_ctx.showLoader)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Você tem " + _toDisplayString(_ctx.maxTime) + " minutos para completar essa atividade. O relógio começa a contar assim que você clicar em “Começar lista”. ", 1))
              : _createCommentVNode("", true),
            (!_ctx.showLoader)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Depois de iniciar, não dá pra pausar o tempo. Se não você não tem " + _toDisplayString(_ctx.maxTime) + " minutos agora, volte quando puder. ", 1))
              : _createCommentVNode("", true),
            (!_ctx.showLoader)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Quer começar a lista agora? "))
              : _createCommentVNode("", true),
            (_ctx.showLoader)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Aguarde um momento... "))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 2
      }, [
        (!_ctx.showLoader)
          ? {
              name: "left-container",
              fn: _withCtx(() => [
                _createVNode(_component_PazzeiButton, {
                  classes: 'with-background-color secondary',
                  text: 'Sim, começar lista',
                  class: "pazzei-button",
                  onClick: _ctx.handleShowLoader
                }, null, 8, ["onClick"])
              ]),
              key: "0"
            }
          : undefined,
        (!_ctx.showLoader)
          ? {
              name: "right-container",
              fn: _withCtx(() => [
                _createVNode(_component_PazzeiButton, {
                  text: 'Cancelar e voltar depois',
                  alt: 'Cancelar e voltar depois',
                  style: _normalizeStyle(`min-width: 192px; ${_ctx.showLoader ? 'height: 252px' : '' }` ),
                  class: "pazzei-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal-limit-time')))
                }, null, 8, ["style"])
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["context"]))
    : _createCommentVNode("", true)
}