
import ContentModalImportStudents from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/ContentModalImportStudents.vue'
import ContentModalRegisterUsers from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/ContentModalRegisterUsers.vue'
import ContentModalReleaseGratuity from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/ContentModalReleaseGratuity.vue'
import TablesComponent from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import PazzeiOptionsMenu from '@/components/Generics/PazzeiOptionsMenu.vue'
import AddIcon from '@/components/Icons/default/AddIcon.vue'
import BlockSchoolIcon from '@/components/Icons/default/BlockSchoolIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import LoginIcon from '@/components/Icons/default/LoginIcon.vue'
import EstatisticsIcon from '@/components/Icons/default/EstatisticsIcon.vue'
import ExportcsvIcon from '@/components/Icons/default/ExportcsvIcon.vue'
import FreeReleaseIcon from '@/components/Icons/default/FreeReleaseIcon.vue'
import HistoricIcon from '@/components/Icons/default/HistoricIcon.vue'
import OptionsIcon from '@/components/Icons/default/OptionsIcon.vue'
import ResendEmailIcon from '@/components/Icons/default/ResendEmailIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import UnBlockSchoolIcon from '@/components/Icons/default/UnBlockSchoolIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'

import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'

import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import authService from '@/services/authService'
import usersService from '@/services/users'
import DefaultTheme from '@/theme/defaultTheme'
import { EntitiesUser, ToggleResourceStatusType, User } from '@/types/UsersType'
import { columnsAdmin, filtersAdmin } from '@/utils/hardCoded/planSchool/users/usersAdmin'
import { columnsUser, filtersUser } from '@/utils/hardCoded/planSchool/users/usersInstitutions'
import { getSubdomainFromLocalStorage } from '@/utils/subdomain'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import AccessHelper from '@/helpers/AccessHelper'

export default defineComponent({
  name: 'ViewUsersPage',
  components: {
    GenericLayout,
    MainLayoutWrapper,
    PazzeiBtn,
    PazzeiInput,
    Modal,
    TablesComponent,
    PaginationTableComponent,
    ContentModalRegisterUsers,
    ModalConfirmation,
    ContentModalReleaseGratuity,
    PazzeiOptionsMenu,
    SearchIcon,
    ContentModalImportStudents,
  },
  setup() {
    const router = useRouter()
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const isModalReleaseGratuity = ref(false)
    const isEditing = ref(false)
    const isFilterSelected = ref(false)
    const optionsPagination = [10, 20, 50, 100]
    const sizeColumnsUsers = ref('1fr / 2.5fr 1.5fr 2.5fr 1.5fr 1fr 0.25fr')
    const rows = ref<User[]>([])
    const getResponse = ref<User[]>([])
    const $q = useQuasar()
    const infoUser = 'Após a confirmação, ele perderá o acesso à plataforma.'
    const isModalConfirmOpen = ref(false)
    const dataTableRow = ref<User>()
    const userPermission = ref(false)
    const permissions = authService.getPermissions()?.permissions
    userPermission.value = permissions?.includes('all:users:users:manage') ?? false
    const optionsMenu = ref<{ label: string; icon: any; iconProps?: any; action: (row?: object) => void }[]>([])
    const innerWidth = ref(window.innerWidth)
    const width = ref('66.875em')
    const isStudentEssential = ref(false)
    const height = ref('36em')
    const widthSmall = ref('43.875em')
    const heightSmall = ref('25.2em')
    const userId = ref<string>('')
    const currentPage = ref(1)
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const totalElements = ref(0)
    const filterName = ref<string | undefined>('')
    const filterNameSearch = ref<string | undefined>('')
    const filterTelephone = ref<string | undefined>('')
    const filterEmail = ref<string | undefined>('')
    const filterDate = ref<string | undefined>('')
    const filterIsActive = ref<boolean | string | undefined>('')
    const filterAccessStatus = ref<boolean | string | undefined>('')
    const filterRole = ref<string | undefined>('')
    const filterPlan = ref<string | undefined>('')
    const isActive = ref<boolean | undefined>(undefined)
    const registrationCompleted = ref<boolean | undefined>(false)
    const heightLoading = ref('10.8em')
    const heightLoadingRootOrGroup = ref('8.1em')
    const sizeInputDaysGratuity = ref('245px')
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const heightTable = ref('53.5em')
    const widthTable = ref('70em')
    const widthTableAdm = ref('70em')
    const sizeEditLoading = ref('3em')
    const changeButton = ref(false)
    let idsByRow: string[] = []
    const isImportStudentsModalOpen = ref(false);

    const handleImportStudentsModal = (flag: boolean) => {
      (isImportStudentsModalOpen.value = flag);
    }

    const openFilterSelected = () => (isFilterSelected.value = !isFilterSelected.value)
    const openModal = () => ((isModalOpen.value = true), (isEditing.value = false))
    const openModalReleaseGratuity = () => (isModalReleaseGratuity.value = true)
    const openEditModal = () => {
      isModalOpen.value = true
      isEditing.value = true
    }
    const closeModal = () => (isModalOpen.value = false)
    const closeModalReleaseGratuity = () => (isModalReleaseGratuity.value = false)
    const handleModalConfirm = () => {
      if (dataTableRow.value && isActive.value) {
        isModalConfirmOpen.value = true
      } else {
        toggleResourceStatus()
      }
    }
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)

    const checkboxSelect = (rowsSelected: Array<string>) => {
      if (rowsSelected.length > 0) {
        changeButton.value = true
        idsByRow = rowsSelected
      } else {
        changeButton.value = false
      }
    }

    const optionsCheckBox = ref([
      { label: 'Inativar', action: () => toggleResourceStatus(idsByRow, 'Inativar') },
      { label: 'Ativar', action: () => toggleResourceStatus(idsByRow, 'Ativar') },
      { label: 'Exportar csv', action: () => downloadCsvFile() },
    ])

    watchEffect(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = isEditing.value ? '28em' : '24em'
      }
      if (innerWidth.value > 768 && innerWidth.value <= 1500) {
        height.value = '36.5em'
        width.value = '60.875em'
      }

      if (innerWidth.value > 1500) {
        height.value = '36.5em'
        width.value = '66.875em'
      }
    })

    onMounted(async () => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        widthSmall.value = '90%'
        height.value = isEditing.value ? '28em' : '24em'
        heightSmall.value = '16.5em'
        sizeLoading.value = '35px'
        sizeColumnsUsers.value = '1fr / 2fr 1.2fr 2fr 1fr 0.7fr 0.25fr'
        heightTable.value = '58vh'
        widthTable.value = '53em'
        heightLoading.value = '12.9em'
        heightLoadingRootOrGroup.value = '9.5em'
        sizeEditLoading.value = '2em'
        sizeInputDaysGratuity.value = '100%'
      }

      if (innerWidth.value > 768 && innerWidth.value <= 1500) {
        height.value = '36.5em'
        width.value = '60.875em'
        sizeInputDaysGratuity.value = '220px'
      }

      await getInstitutionUsers()
    })

    const handleFiltersApplied = (filterValues: User) => {
      filterName.value = filterValues.name
      filterTelephone.value = filterValues.telephone
      filterEmail.value = filterValues.email
      filterRole.value = filterValues.roleDescription
      filterPlan.value = filterValues.plan
      filterIsActive.value = filterValues.isActive
      filterAccessStatus.value = filterValues.roleCode
      filterDate.value = filterValues.createdAt

      debouncedGetInstitutionUsers()
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getInstitutionUsers()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getInstitutionUsers()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getInstitutionUsers = async () => {
      const searchByName = filterNameSearch.value !== '' ? { name: filterNameSearch.value } : {}
      const searchByOtherField = filterName.value !== '' ? { name: filterName.value } : {}

      const requestBodyAll = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          ...searchByName,
          ...searchByOtherField,
          email: filterEmail.value !== '' ? filterEmail.value : undefined,
          telephone: filterTelephone.value !== '' ? filterTelephone.value : undefined,
          roleDescription: filterRole.value !== '' ? filterRole.value : undefined,
          plan: filterPlan.value !== '' ? filterPlan.value : undefined,
          isActive: filterIsActive.value !== '' ? (filterIsActive.value === 'Ativo' ? true : false) : undefined,
          hasAccess: filterAccessStatus.value !== '' ? (filterAccessStatus.value === 'Liberado' ? true : false) : undefined,
          date: filterDate.value !== '' ? filterDate.value : undefined,
        },
      }

      const requestBodyRootOrGroup = {
        page: currentPage.value,
        pageSize: pageSize.value,
        search: {
          ...searchByName,
          ...searchByOtherField,
          email: filterEmail.value !== '' ? filterEmail.value : undefined,
          roleDescription: filterRole.value !== '' ? filterRole.value : undefined,
          telephone: filterTelephone.value !== '' ? filterTelephone.value : undefined,
          isActive: filterIsActive.value !== '' ? (filterIsActive.value === 'Ativo' ? true : false) : undefined,
          date: filterDate.value !== '' ? filterDate.value : undefined,
        },
      }

      try {
        isLoading.value = true
        const response = await usersService.post<ResponseDTO<EntitiesUser>>('/filter-users', userPermission.value ? requestBodyAll : requestBodyRootOrGroup)
        getResponse.value = response.data.data.entities
        rows.value = getResponse.value.map((row: User) => {
          return {
            id: row.id,
            name: `${row.name} ${row.lastName !== null ? row.lastName : ''}`,
            email: row.email,
            telephone: row.telephone,
            createdAt: row.createdAt,
            roleDescription: row.roleDescription,
            plan: row.plan,
            isActive: row.isActive,
            isRegistrationCompleted: row.isRegistrationCompleted,
            roleCode: row.roleCode === 'pazzei:no-credits' || !row.isActive ? 'Bloqueado' : 'Liberado',
            autoRenew: row.autoRenew ?? undefined,
          }
        })

        totalElements.value = response.data.data.totalElements
      } catch (error) {
        notification(false, 'Erro ao buscar dados')
      } finally {
        isLoading.value = false
      }
    }
    const debouncedGetInstitutionUsers = debounce(getInstitutionUsers, 500)

    watch(filterNameSearch, () => {
      currentPage.value = 1
      debouncedGetInstitutionUsers()
    })

    watch(isFilterSelected, () => {
      filterNameSearch.value = ''
      currentPage.value = 1
      if (
        filterName.value !== '' &&
        filterTelephone.value !== '' &&
        filterEmail.value !== '' &&
        filterRole.value !== '' &&
        filterPlan.value !== '' &&
        filterIsActive.value !== '' &&
        filterDate.value !== ''
      ) {
        debouncedGetInstitutionUsers()
      }
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 16,
    }

    const createOptionsMenu = (): { label: string; icon: any; iconProps?: any; action: (row?: object) => void; disabled?: boolean }[] => {
      const commonOptions = [{
         label: 'Editar Cadastro',
         icon: EditRegisterIcon,
         iconProps,
         action: () => openEditModal?.()
        },
        { label: '', icon: '', action: handleModalConfirm },
        {
          label: 'Simular Acesso',
          icon: LoginIcon,
          iconProps,
          action: () => simulateAccess?.(),
        }
      ]

      if (userPermission.value) {
        if (registrationCompleted.value) {
          return [
            ...commonOptions,
            {
              label: 'Liberar Gratuidade',
              icon: FreeReleaseIcon,
              iconProps,
              action: () => openModalReleaseGratuity?.()
          },
            {
              label: 'Ver Histórico',
              icon: HistoricIcon,
              iconProps,
              action: (row: any) => router.push({ name: 'viewPurchaseHistory', params: { id: row.id } })
             },
          ]
        } else {
          return [
            ...commonOptions,
            {
              label: 'Liberar Gratuidade',
              icon: FreeReleaseIcon,
              iconProps,
              action: () => openModalReleaseGratuity?.()
          },
            {
              label: 'Reenviar E-mail',
              icon: ResendEmailIcon,
              iconProps,
              action: () => resendEmail?.()
           },
            {
              label: 'Ver Histórico',
              icon: HistoricIcon,
              iconProps,
              action: (row: any) => router.push({ name: 'viewPurchaseHistory', params: { id: row.id } })
             },
          ]
        }
      } else {
        if (registrationCompleted.value) {
          return [...commonOptions, {
              label: 'Ver estatísticas',
              icon: EstatisticsIcon,
              iconProps,
              action: () => '',
              disabled: true
            }]
        } else {
          return [
            ...commonOptions,
            {
              label: 'Reenviar E-mail',
              icon: ResendEmailIcon,
              iconProps,
              action: () => resendEmail?.()
            },
            {
              label: 'Ver estatísticas',
              icon: EstatisticsIcon,
              iconProps,
              action: () => '',
              disabled: true
              },
          ]
        }
      }
    }

    const handleValueUpdated = (value: User) => {
      dataTableRow.value = value
      isActive.value = value.isActive
      userId.value = value.id
      registrationCompleted.value = value.isRegistrationCompleted

      if (value.autoRenew !== undefined) {
        isStudentEssential.value = true
      } else {
        isStudentEssential.value = false
      }

      if (isActive.value !== undefined) {
        optionsMenu.value = createOptionsMenu()
        optionsMenu.value[1].label = `${isActive.value ? 'Inativar' : 'Ativar'} Usuário`
        optionsMenu.value[1].icon = isActive.value ? BlockSchoolIcon : UnBlockSchoolIcon
        optionsMenu.value[1].iconProps = iconProps
      }
    }

    provide('emitNewValue', handleFiltersApplied)
    provide('heightLoading', userPermission.value ? heightLoading : heightLoadingRootOrGroup)
    provide('openFilterSelected', openFilterSelected)
    provide('isFilterSelected', isFilterSelected)
    provide('emitValueToParent', handleValueUpdated)
    provide('optionsMenu', optionsMenu)
    provide('filters', userPermission.value ? filtersAdmin : filtersUser)

    const toggleResourceStatus = async (array?: Array<string>, label?: string) => {
      const data = { userIds: array && array.length ? array : [userId.value] }
      try {
        if (isActive.value || label === 'Inativar') {
          await usersService.put<Promise<ToggleResourceStatusType>>('/deactivate', data)
        } else {
          await usersService.put<Promise<ToggleResourceStatusType>>('/activate', data)
        }
        notification(true, `Usuário ${!isActive.value ? 'ativado' : 'inativado'} com sucesso`)
        getInstitutionUsers()
      } catch (error) {
        notification(false, 'Erro ao alternar o status do usuário')
      }
    }

    const downloadCsvFile = async () => {
      try {
        const documentBodyRef = ref(document?.body);

        if (documentBodyRef.value) {
          const response = await usersService.post<ResponseDTO<any>>('/csv', { userIds: idsByRow.length ? idsByRow : undefined })
          const fileData = decodeURIComponent(escape(window.atob(response.data.data)))
          const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' })
          const link = document.createElement('a')
          const url = URL.createObjectURL(blob)

          link.setAttribute('href', url)
          link.setAttribute('download', 'Usuários.csv')
          link.style.visibility = 'hidden'
          documentBodyRef.value?.appendChild?.(link);
          documentBodyRef.value?.appendChild?.(link);
          link.click()
          documentBodyRef.value?.removeChild?.(link);
        }
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao exportar CSV')
      }
    }

    const resendEmail = async () => {
      const subdomain = getSubdomainFromLocalStorage()
      try {
        await usersService.patch('resend', { id: userId.value, subdomain})
        notification(true, 'Solicitação de reenvio de e-mail realizada com sucesso')
      } catch (error) {
        notification(false, 'Falha na solicitação de reenvio de e-mail')
      }
    }

    const simulateAccess = async () => {
      await AccessHelper(userId.value)
      .then(() => notification(true, 'Criando simulação de acesso...'))
      .catch((error) => notification(false, `Falha ao simular acesso. ${error.message}`))
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      isSearchInputExpanded,
      isModalOpen,
      openModal,
      optionsPagination,
      innerWidth,
      width,
      height,
      closeModal,
      closeModalReleaseGratuity,
      isEditing,
      openEditModal,
      openFilterSelected,
      isFilterSelected,
      columnsUser,
      filtersAdmin,
      filtersUser,
      sizeInputDaysGratuity,
      userPermission,
      columnsAdmin,
      sizeColumnsUsers,
      isModalConfirmOpen,
      infoUser,
      toggleResourceStatus,
      dataTableRow,
      rows,
      getInstitutionUsers,
      userId,
      handlePageSizeChange,
      pageSize,
      handleCurrentPage,
      isLoading,
      sizeLoading,
      totalElements,
      handleFiltersApplied,
      filterNameSearch,
      heightTable,
      widthTable,
      widthTableAdm,
      sizeEditLoading,
      isModalReleaseGratuity,
      widthSmall,
      heightSmall,
      checkboxSelect,
      changeButton,
      optionsCheckBox,
      isStudentEssential,
      downloadCsvFile,
      DefaultTheme,
      OptionsIcon,
      AddIcon,
      ExportcsvIcon,
      isImportStudentsModalOpen,
      handleImportStudentsModal,
      simulateAccess,
    }
  },
})
