
import ForwardIcon from '@/components/Icons/default/redesign/sharedList/ForwardIcon.vue'
import ReturnIcon from '@/components/Icons/default/redesign/sharedList/ReturnIcon.vue'
import ListQuestionsNavigation from '@/components/ListQuestionsNavigation/ListQuestionsNavigation.vue'
import { CorrectAndSelectedAlternativeDTO } from '@/dtos/CorrectAndSelectedAlternativeDTO'
import { AlternativeDTO } from '@/dtos/ResponseDTOs/AlternativeDTO'
import { QuestionDTO } from '@/dtos/ResponseDTOs/QuestionDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { TagDTO } from '@/dtos/ResponseDTOs/TagDTO'
import { UserResponseDTO } from '@/dtos/ResponseDTOs/UserResponseDTO'
import questionsService from '@/services/question'
import DefaultTheme from '@/theme/defaultTheme'
import axios from 'axios'
import { defineComponent, inject, onMounted, onUnmounted, PropType, reactive, Ref, ref, watch, watchEffect } from 'vue'
import AlternativesSelector from '../../Application/alternativesSelector/AlternativesSelector.vue'
import Alternative from '../../Application/alternativesSelector/Redesign/Alternative.vue'
import { AlternativesSelectorModelValueType } from '../../Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import PazzeiButton from '../../Generics/Redesign/PazzeiButton.vue'
import PazzeiToggle from '../../Generics/Redesign/PazzeiToggle.vue'

export default defineComponent({
  name: 'QuestionWrapper',
  emits: [
    'handle-feedback',
    'update:modelValue',
    'get-question-type',
    'get-question-answer',
    'get-tags',
    'stop-loading',
    'getCorrectAlternatives',
    'next-question',
    'previous-question',
    'mark-as-doubt',
    'increment-not-found-questions',
  ],
  props: {
    questionId: Number,
    answers: Object as PropType<Array<UserResponseDTO>>,
    listId: Number,
    feedback: Boolean,
    isInstantFeedback: { type: Boolean, default: false },
    modelValue: { type: Object as PropType<AlternativesSelectorModelValueType>, default: () => ({}) },
    correctAndSelect: Object as PropType<CorrectAndSelectedAlternativeDTO>,
    selected: Number,
    correctAlternatives: { type: Array as PropType<Array<number>>, default: () => [] },
    modal: Boolean,
    modalAnsweredQuestion: Boolean,
    maxWidthTotal: Boolean,
    summary: Boolean,
    paddingRightAlternatives: Boolean,
    variant: { type: String as PropType<'primary'>, default: '' },
    page: { type: String as PropType<'check-answers'>, default: '' },
    questionNumber: { type: Number, default: 0 },
    totalQuestions: { type: Number, default: 0 },
    isDoubt: Boolean,
    isMobile: { type: Boolean, required: true },
  },
  components: {
    ListQuestionsNavigation,
    Alternative,
    AlternativesSelector,
    PazzeiButton,
    PazzeiToggle,
  },
  setup(props, { emit }) {
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const summationModel = ref(0)
    const questionType = ref<string>('')
    const showAnswer = ref(false)
    const controller = new AbortController()
    const questionDTO = ref<QuestionDTO>()
    const selectedAlternative = ref<number>()
    const isQuestionLoading = ref<boolean>(false);
    const isLoadingAlternatives = ref<boolean>(false);
    const isLoadingBaseText = ref<boolean>(false);
    const getTag = inject<Ref<Array<TagDTO>>>('topicTag')
    const optionCurrentSelected = ref<number | null>(0)
    const alternatives = ref(Array<AlternativeDTO>())
    const questionHTML = ref<any>()
    const textBaseHTML = ref<any>()
    const alternativeHTML = ref<Record<string, any>>({})
    const showDebugId = ref(false)
    const alternativeSelectedOnInstantFeedback = ref<Array<number>>([])
    const timeOut = ref(false)
    const correctAlternativeIds = ref<Array<number>>([])
    const questionNotDownloaded = ref(false)
    const showLoading = ref(true)
    const count = ref(0)
    const correctAlternativesSummary = ref<Array<number>>([])
    const answerHtmlURL = ref<string | null>(null);
    let interval = 0
    let letIntervalBe = 2000

    const alternativeObject = reactive({
      selected: false,
    });

    const { updateQuestionAws } = inject<{ questionAws?: string; updateQuestionAws: (awsKey?: string) => void }>('questionAwsKey', {
      questionAws: '',
      updateQuestionAws: () => {
        return
      },
    })

    const goBack = () => {
      emit('previous-question', props.questionNumber);
    }
    const goForward = () => {
      emit('next-question', props.questionNumber);
    }

    const setAnswerDetails = async (questionData: QuestionDTO) => {
      if (!questionData.resolutionAwsKey) {
        answerHtmlURL.value = ''
        return;
      }

      const url = process.env.VUE_APP_QUESTIONS_ALTERNATIVES_BASE_URL.trim() + questionDTO.value?.resolutionAwsKey;
      const resolutionResponse = await axios.get(url);

      if (resolutionResponse?.data) {
        answerHtmlURL.value = resolutionResponse.data;
      }
    }

    const fetchQuestionHtml = async (resourceUrl: string): Promise<string> => {
      isQuestionLoading.value = true;
      try {
        const questionHtmlResponse = await axios.get<string>(resourceUrl, { signal: controller.signal })
        return questionHtmlResponse.data;
      } catch (err) {
        showLoading.value = false
        isQuestionLoading.value = false;
        // increments not found questions on fetch error
        emit('increment-not-found-questions', props.questionId)
        throw Error('Falha ao buscar corpo da questão')
      } finally {
        isQuestionLoading.value = false;
      }
    }

    const fetchIndividualAlternative = async (resourceKey: string): Promise<any> => {
      const resourceUrl = process.env.VUE_APP_QUESTIONS_ALTERNATIVES_BASE_URL + resourceKey;

      try {
        const responseAlternative = await axios.get<string>(
          resourceUrl, { signal: controller.signal }
        )

        return responseAlternative.data;
      } catch (err) {
        console.warn('Alternativa', resourceKey, 'não encontrada.')
      }
    }


    const getAndSetQuestionAlternatives = async (incomingAlternatives: AlternativeDTO[]): Promise<string[]> => {
      isLoadingAlternatives.value = true;

      const retrievedAlternativesSorted = incomingAlternatives.sort((a, b) => a.order! - b.order!)
      retrievedAlternativesSorted.forEach((alt) => {
        alternativeHTML.value[alt.awsKey] = ''
      })

      const filteredUniqueAlternatives = new Set([...incomingAlternatives, ...retrievedAlternativesSorted]);
      const uniqueAlternatives = [...filteredUniqueAlternatives];
      alternatives.value = uniqueAlternatives;

      const fetchedAlternatives = await Promise.all(uniqueAlternatives.map(async (alternativeReference) => {
        const fetchedItem = await fetchIndividualAlternative(alternativeReference.awsKey);

        if (fetchedItem) {
          alternativeHTML.value[alternativeReference.awsKey] = fetchedItem;
        }

        return fetchedItem;
      }))

      isLoadingAlternatives.value = false

      return fetchedAlternatives;
    }

    const fetchQuestionBaseTextHtml = async (resourceUrl: string): Promise<string> => {
      isLoadingBaseText.value = true;
      try {
        const responseBaseTextHTML = await axios.get(resourceUrl, { signal: controller.signal });

        if (responseBaseTextHTML && responseBaseTextHTML.data) {
          return responseBaseTextHTML.data;
        } else throw Error('Falha ao buscar texto base da questão')

      } catch (error) {
        throw Error('Falha ao buscar texto base da questão')
      } finally {
        isLoadingBaseText.value = false;
      }
    }

    const foundQuestion = ref<boolean>(false)

    const polyfillQuestionInfo = async (fetchedQuestion: QuestionDTO | null) => {
      if (!fetchedQuestion) {
        emit('increment-not-found-questions', props.questionId)
        foundQuestion.value = false
        showLoading.value = false
        return
      }

      const questionURL = process.env.VUE_APP_AWS_QUESTIONS_BASE_URL + fetchedQuestion.awsKey;
      const textBaseURL = process.env.VUE_APP_AWS_QUESTIONS_TEXTS_BASE_URL + fetchedQuestion.baseTextAwsKey;

      questionType.value = fetchedQuestion.tags.find((tag) => tag.key === 'type')?.value || ''

      if (props.summary) {
        correctAlternativesSummary.value = fetchedQuestion.correctAlternativeIds || []
      }

      if (getTag) getTag.value = fetchedQuestion.tags

      updateQuestionAws(fetchedQuestion.resolutionAwsKey)

      emit('get-tags', fetchedQuestion.tags)
      emit('get-question-type', questionType.value)
      emit('getCorrectAlternatives', fetchedQuestion.correctAlternativeIds)

      if (props.isInstantFeedback && fetchedQuestion.correctAlternativeIds
        && fetchedQuestion.correctAlternativeIds.length > 0
        && fetchedQuestion.selectedAlternativeIds.length > 0
      ) {
        showAnswer.value = true
        correctAlternativeIds.value = fetchedQuestion.correctAlternativeIds || []
      }

      const fetchedQuestionHtml = await fetchQuestionHtml(questionURL)

      if (fetchedQuestionHtml && fetchedQuestionHtml.length) {
        questionHTML.value = fetchedQuestionHtml
        showLoading.value = false
        foundQuestion.value = true
        clearTimeout(interval)
      }

      await getAndSetQuestionAlternatives(fetchedQuestion.alternatives);

      const fetchedBaseTextHtml = await fetchQuestionBaseTextHtml(textBaseURL);

      if (fetchedBaseTextHtml && fetchedBaseTextHtml?.length) {
        textBaseHTML.value = fetchedBaseTextHtml;
      }

      questionDTO.value = fetchedQuestion;

      if (props.answers?.length) {
        const alternativeId = props.answers.find((answer) => props.questionId === answer.questionId)?.alternativesIds
        if (alternativeId) {
          selectedAlternative.value = alternativeId as any
        }
        const currentSelected = fetchedQuestion.alternatives.map((it) => it).findIndex(
          (it) => { it.id === selectedAlternative.value })

        if (currentSelected) {
          optionCurrentSelected.value = currentSelected
        } else optionCurrentSelected.value = null
      } else optionCurrentSelected.value = null
    }

    const initQuestion = async () => {
      emit('stop-loading', false)
      count.value++
      if (letIntervalBe < 10000) letIntervalBe *= 2
      else letIntervalBe = 10000

      let fetchedQuestion = null

      try {
        const listQuestionResponse = await questionsService.get<ResponseDTO<QuestionDTO>>(
          `/${props.listId}/${props.questionId}`,
          { signal: controller.signal }
        )

        fetchedQuestion = listQuestionResponse.data.data;
        questionDTO.value = fetchedQuestion;
      } catch (error) {
        clearTimeout(interval)
        questionNotDownloaded.value = true
      } finally {
        emit('handle-feedback', !questionNotDownloaded.value && !showLoading.value);
        emit('stop-loading', true);
      }

      await polyfillQuestionInfo(fetchedQuestion);
    }

    const showDebug = (e: Event) => {
      if (e instanceof KeyboardEvent)
        if (e.key === 'd') {
          showDebugId.value = !showDebugId.value
          sessionStorage.setItem('debug', String(showDebugId.value))
        }
    }

    const handleClickSeeAnswerQuestion = async () => {
      if (!showAnswer.value) {
        const response = await questionsService.get<ResponseDTO<QuestionDTO>>(`/${props.listId}/${props.questionId}`, { signal: controller.signal });
        questionDTO.value = response.data.data; // atualiza informacões da questão com resposta

        emit('get-question-answer', alternativesModel.value);
        showAnswer.value = true;
      }
    }

    document.addEventListener('keydown', showDebug)

    watch(questionDTO, async () => {
      if (showAnswer.value && questionDTO.value) {
        await setAnswerDetails(questionDTO.value)
      }
    });


    watch(alternativesModel, () => {
      emit('update:modelValue', alternativesModel.value)
    })

    watch(
      () => props.modelValue,
      () => {
        alternativesModel.value = props.modelValue.questionId ? props.modelValue : undefined

        emit('update:modelValue', props.modelValue)

        if (props.isInstantFeedback && props.modelValue.alternatives && props.modelValue.alternatives.length) {
          alternativeSelectedOnInstantFeedback.value = props.modelValue.alternatives as number[]
        }
      },
      {
        deep: true,
        immediate: true,
      }
    )

    watchEffect(() => {
      // if (questionType.value.includes('Múltipla escolha') && questionDTO.value && props.page === 'check-answers') showAnswer.value = true
      // if (questionType.value.includes('Múltipla escolha') && props.page !== 'check-answers') showAnswer.value = true
      // if (!questionType.value.includes('Múltipla escolha') && props.feedback) showAnswer.value = true

      if (props.correctAlternatives && props.correctAlternatives.length) correctAlternativeIds.value = props.correctAlternatives
      if (props.feedback) alternativeSelectedOnInstantFeedback.value = props.correctAndSelect?.responseAlternativeIds || []
    })

    onMounted(async () => {
      setTimeout(() => {
        timeOut.value = true
      }, 1500)

      if (!questionDTO.value) await initQuestion()

      const debug = sessionStorage.getItem('debug')
      if (debug === 'true') showDebugId.value = true
      else showDebugId.value = false
    })

    onUnmounted(() => {
      controller.abort()
      clearTimeout(interval)
      document.removeEventListener('keydown', showDebug)
    })

    return {
      alternativesModel,
      selectedAlternative,
      questionDTO,
      optionCurrentSelected,
      showAnswer,
      correctAlternativeIds,
      alternatives,
      textBaseHTML,
      questionHTML,
      correctAlternativesSummary,
      summationModel,
      alternativeSelectedOnInstantFeedback,
      handleClickSeeAnswerQuestion,
      alternativeHTML,
      questionType,
      showDebugId,
      timeOut,
      questionNotDownloaded,
      showLoading,
      goBack,
      goForward,
      alternativeObject,
      DefaultTheme,
      ForwardIcon,
      ReturnIcon,
      answerHtmlURL,
      isLoadingAlternatives,
      isLoadingBaseText,
      foundQuestion,
    }
  },
})
