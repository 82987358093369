import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07994c2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pre-content" }
const _hoisted_2 = { class: "div-nav" }
const _hoisted_3 = { class: "sub-nav" }
const _hoisted_4 = { class: "pre-content pre-content__mobile" }
const _hoisted_5 = { class: "div-nav div-nav__mobile" }
const _hoisted_6 = { class: "list-items__actionsContainer" }
const _hoisted_7 = { class: "sub-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "pre-content", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "expand", {}, undefined, true),
            _renderSlot(_ctx.$slots, "filter", {}, undefined, true),
            _renderSlot(_ctx.$slots, "nav", {}, undefined, true),
            _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "filter-tags", {}, undefined, true)
          ])
        ], 64))
      : (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "pre-content", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "nav", {}, undefined, true),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "expand", {}, undefined, true),
                _renderSlot(_ctx.$slots, "filter", {}, undefined, true)
              ]),
              _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "filter-tags", {}, undefined, true)
            ])
          ], 64))
        : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["main-wrapper", { 'concluded-lists-wrapper': _ctx.exerciseListConcluded.length, 'display-inline': _ctx.displayCardsInline }])
    }, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ], 2)
  ], 64))
}