
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiSimpleSelect from '@/components/Generics/PazzeiSimpleSelect.vue'
import Input from '@/components/Library/Input/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { PlanSchoolCreateUserDTO, PlanSchoolUserDTO } from '@/dtos/schoolPlanPages/UserDTO/PlanSchoolUserDTO'
import InstitutionConfigHelper from '@/helpers/InstitutionConfigHelper'
import authenticationService from '@/services/authentication'
import groupsService from '@/services/groups'
import usersService from '@/services/users'
import { InstitutionEntities } from '@/types/InstitutionType'
import { PageType } from '@/types/PageType'
import { VirtualScrollType } from '@/types/VirtualScrollType'
import email from '@/utils/vuelidateRules/emailRules'
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { PropType, computed, defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ModalRegisterUser',
  emits: ['close'],
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      required: false,
      default: '',
    },
    institutionId: {
      type: Number,
      required: false,
    },
    listUser: Function as PropType<() => void>,
    sizeEditLoading: {
      type: String,
      default: '245px',
    },

    sizeInputDaysGratuity: {
      type: String,
    },
    userPermission: {
      type: Boolean,
      default: false,
    },
    isStudentEssential: {
      type: Boolean,
      default: false,
    },
    isPageStudentIntitution: {
      type: Boolean,
      default: false,
    },
  },
  components: { Input, PazzeiBtn, PazzeiSimpleSelect },

  setup(props, { emit }) {
    const visibleModal = ref(true)
    const $q = useQuasar()
    const isLoading = ref(false)
    const messageRequired = 'Este campo é obrigatório'
    const passwordRules = helpers.withMessage(
      'A senha não deve ter espaços, precisa incluir pelo menos uma letra maiúscula, um número e um caractere especial',
      helpers.withParams(
        { type: 'passwordRules' },
        (value: unknown) => 
        typeof value === 'string' &&
        (!value || /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{6,}$/.test(value))
        //regex quanto a espaços, letra maiúscula, número e caractere especial.
      )
    );
    const isPwd = ref(true)
    const roleOptions = ref<Array<string>>([])
    const callDataAgain = ref()
    const isFetchData = ref(false)
    const institutionsOptions = ref<Array<{ name: string; id: number }>>([])
    const registrationCompleted = ref(false)
    const formData = ref<{ credits: string | undefined; name: string; lastName: string; email: string; password: string; confirmPassword: string;  role: Array<string>; institution: { name: string; id: number } }>({
      credits: '',
      name: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: [],
      institution: { name: '', id: -1 },
    })
    const changeRecurrence = ref<boolean | undefined>(false)

    const rules = computed(() => {
      if (props.isEditing)
        return {
          name: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do usuário deve ter no mínimo 3 caracteres', minLength(3)) },
          lastName: { minLength: helpers.withMessage('O sobrenome do usuário deve ter no mínimo 3 caracteres', minLength(3)) },
          role: { required: helpers.withMessage(messageRequired, required) },
          institution: { required: helpers.withMessage(messageRequired, required) },
          password: { 
            minLength: helpers.withMessage('A senha deve conter no mínimo 6 caracteres',minLength(6)),
            passwordRules,
          },
          confirmPassword: { 
            samePassword: helpers.withMessage(
              'A confirmação de senha deve ser igual à senha',
              helpers.withParams({ type: 'samePassword' }, (value, { password }) => !password || value === password) 
            ),
          },
          email,
        }
      else
        return {
          name: { required: helpers.withMessage(messageRequired, required), minLength: helpers.withMessage('O nome do usuário deve ter no mínimo 3 caracteres', minLength(3)) },
          role: { required: helpers.withMessage(messageRequired, required) },
          institution: { required: helpers.withMessage(messageRequired, required) },
          email,
        }
    })
    const v$ = useVuelidate(rules, formData)

    const pageInfos: PageType = { totalPages: undefined, lastPageRequested: 0 }

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const handleScroll = (details: VirtualScrollType, itemsLength: number) => {
      if (details.index + 1 >= itemsLength && pageInfos.totalPages && pageInfos.totalPages > pageInfos.lastPageRequested) getInstitutions()
    }

    const fetchUserData = async (userId: string) => {
      isFetchData.value = true

      try {
        const { data: infos } = await usersService.get<ResponseDTO<PlanSchoolUserDTO>>(`/${userId}`)
        registrationCompleted.value = infos.data.isRegistrationCompleted ?? false
        formData.value.name = infos.data.name || ''
        formData.value.lastName = infos.data.lastName || ''
        formData.value.email = infos.data.email || ''
        formData.value.role[0] = infos.data.roleDescription || ''
        formData.value.institution = { name: institutionsOptions.value.find((it) => it.id === infos.data.institutionId)?.name || '', id: infos.data.institutionId || -1 }
        changeRecurrence.value = infos.data.autoRenew
        callDataAgain.value++
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao buscar dados')
      } finally {
        isFetchData.value = false
      }
    }

    const getInstitutions = async () => {
      isFetchData.value = true
      const requestBody = { filter: {}, page: ++pageInfos.lastPageRequested, pageSize: 100, search: {} }

      try {
        const { data } = await groupsService.post<ResponseDTO<InstitutionEntities>>('/institutions/list', requestBody)
        pageInfos.totalPages = data.data.totalPages

        const dataAux = data.data.entities.map((institution) => ({
          name: institution.name,
          id: institution.id,
        }))

        institutionsOptions.value = [...institutionsOptions.value, ...dataAux]
      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao buscar instituições')
      }
    }

    onMounted(async () => {
      if (props.isEditing) {
        if (props.userPermission) await getInstitutions()
        await fetchUserData(props.userId)
      }

      fetchRoleOptions()
    })

    const filterOptions = async (roles: string[]) => {
      const institutionConfig = await InstitutionConfigHelper.getInstitutionsConfig();
      if(!institutionConfig?.isWhiteLabel) {
        return roles.filter(role => role.toLowerCase() !== "white label")
      }
      return roles;
    }

    const fetchRoleOptions = async () => {
      try {
        const token = localStorage.getItem('token') ?? sessionStorage.getItem('token')
        const response = await authenticationService.get('/roles', { headers: { Authorization: `Bearer ${token}` } })
        roleOptions.value = await filterOptions(response.data.data)

      } catch (error) {
        console.error(error)
        notification(false, 'Erro ao buscar funções')
      }
    }

    const saveResourceUser = async () => {
      const result = await v$.value.$validate()
      if (result) {
        isLoading.value = true

        try {
          if (props.isEditing) {
            const data = new PlanSchoolUserDTO(
              formData.value.name,
              formData.value.email,
              formData.value.role[0],
              props.userId,
              props.userPermission ? formData.value.institution.id : undefined,
              formData.value.lastName,
              changeRecurrence.value ?? undefined
            )

            await usersService.patch('/update', data)

            if(formData.value.password && formData.value.confirmPassword ) {
              const updatePasswordData = { 
                email: formData.value.email,
                password: formData.value.password,
              } 
              await usersService.patch('/update-user', updatePasswordData)
            }

          } else {
            const data = new PlanSchoolCreateUserDTO(
              formData.value.name,
              formData.value.email,
              formData.value.role[0],
              formData.value.credits !== '' ? Number(formData.value.credits) : undefined,
              props.institutionId
            )
            await usersService.post('/pre-registration', data)
          }

          notification(true, 'Dados salvos com sucesso')
        } catch (error) {
          console.error(error)
          notification(false, 'Erro ao salvar dados')
        } finally {
          props.listUser?.()
          isLoading.value = false
          emit('close')
        }
      }
    }

    return {
      visibleModal,
      roleOptions,
      handleScroll,
      v$,
      formData,
      institutionsOptions,
      isLoading,
      saveResourceUser,
      callDataAgain,
      changeRecurrence,
      isFetchData,
      registrationCompleted,
      isPwd
    }
  },
})
