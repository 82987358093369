
import ModalFilterList from '@/components/FilterList/ModalFilterList.vue'
import ModalLimitTime from '../components/Generics/Redesign/ModalLimitTime.vue'
import ModalClearFilters from '@/components/FilterList/ModalClearFilters.vue'
import ModalPrintList from '@/components/Generics/Redesign/ModalPrintList.vue'
import SubjectList from '@/components/Generics/Redesign/PazzeiSubjectList/PazzeiSubjectList.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import RedesignedSubWrapper from '@/components/Layouts/RedesignedSubWrapper.vue'

import FilterTag from '@/components/Generics/Redesign/FilterTag.vue'
import PazzeiButton from '@/components/Generics/Redesign/PazzeiButton.vue'
import CheckIcon from '@/components/Icons/default/redesign/sharedList/CheckIcon.vue'
import FilterLinesIcon from '@/components/Icons/default/redesign/sharedList/FilterLinesIcon.vue'
import RecycleBinIcon from '@/components/Icons/default/redesign/sharedList/RecycleBinIcon.vue'
import ReturnIcon from '@/components/Icons/default/redesign/sharedList/ReturnIcon.vue'
import SearchMdIcon from '@/components/Icons/default/redesign/sharedList/SearchMdIcon.vue'
import ListSubscriptionComponent from '@/components/SharedList/ListSubscription/ListSubscriptionComponent.vue'
import { PagedDTO } from '@/dtos/ResponseDTOs/PagedDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { UserDTO } from '@/dtos/ResponseDTOs/UserDTO'
import { ListTryDTO, SubjectInfoDTO, UserHasListDTO } from '@/dtos/SubjectInfoDTO'
import { FilterListsDTO, GenericFilterDTO } from '@/dtos/modal/FilterListsDTO'
import { TermsURLData } from '@/dtos/registerPage/termsURLDTO'
import { listStatus } from '@/enum/listStatusEnum'
import listService from '@/services/list'
import DefaultTheme from '@/theme/defaultTheme'
import { Ref, defineComponent, inject, onMounted, onUnmounted, provide, ref, watch } from 'vue'
import CustomCTA from '../components/Generics/Redesign/CustomCTA.vue'
import CustomDropdown from '../components/Generics/Redesign/CustomDropdown.vue'
import ListPage from '../components/Generics/Redesign/ListPage/ListPage.vue'
import PazzeiInput from '../components/Generics/Redesign/PazzeiInput.vue'
import { useScreenSize } from '@/composables/screen-size'
import { InstitutionConfigType } from '@/types/InstitutionConfigType'

export default defineComponent({
  name: 'ExerciseListPage',
  components: {
    ListSubscriptionComponent,
    GenericLayout,
    PazzeiInput,
    RedesignedSubWrapper,
    MainLayoutWrapper,
    SubjectList,
    ModalFilterList,
    ModalPrintList,
    ModalClearFilters,
    ModalLimitTime,
    PazzeiButton,
    FilterTag,
    CustomCTA,
    CustomDropdown,
    ListPage,
    SearchMdIcon,
    CheckIcon
  },
  setup() {
    const controller = new AbortController()
    const isExerciseList = ref(true)
    const termsNeedAccept = ref<TermsURLData[]>([])
    const exerciseList = ref<SubjectInfoDTO[]>([])
    const filteredExerciseList = ref<SubjectInfoDTO[]>([])
    const exerciseListConcluded = ref<SubjectInfoDTO[]>([])
    const isFiltering = ref(false);
    const showModal = ref(false);
    const showSelectedFilters = ref(false);
    const seeAllTheLists = ref(false);
    const showListPreview = ref(false);
    const selectedList = ref<SubjectInfoDTO>();
    const searchList = ref('')
    const foundFlag = ref(true)
    const show = ref(false)
    const isModalVisible = ref(false);
    const showClearFiltersConfirmModal = ref(false);
    const notFound = ref(1)
    const isSearchInputExpanded = ref(true)
    const showPrintListModal = ref(false);
    const showLimitTimeModal = ref(false);
    const isLoading = ref(true)
    const infinityScrollPage = ref(0)
    const infinityScrollPageSize = ref(20)
    const filterStatus = ref<FilterListsDTO[]>([
      { id: 1, label: 'A fazer', isActive: false },
      { id: 2, label: 'Iniciada', isActive: false },
      { id: 3, label: 'Concluída', isActive: false },
      { id: 4, label: 'Prazo encerrado', isActive: false },
    ]);
    const filterAvailableAnswers = ref<GenericFilterDTO>({ isActive: false });
    const filterListCreators = ref<UserHasListDTO[]>([]);
    let key = ref(0);

    const getUser = inject<Ref<UserDTO>>('userDTO')
    const institutionConfig = inject<InstitutionConfigType>('institutionConfig');

    const screen = useScreenSize();

    const dropDownOptions = [
      { id: 1, label: 'Mais recentes primeiro' },
      { id: 2, label: 'Menor prazo primeiro' },
      { id: 3, label: 'Ordem alfabética' },
    ]

    function handleSelectedOption(id: number) {
      filteredExerciseList.value = [...exerciseList.value];
      isFiltering.value = true;
      switch (id) {
        case 1:
          filteredExerciseList.value = filteredExerciseList.value.sort((a, b) => {
            const dateA = new Date(a.listTries[0]?.createdAt || 0).getTime();
            const dateB = new Date(b.listTries[0]?.createdAt || 0).getTime();
            return dateB - dateA;
          });
          break;

        case 2:
          filteredExerciseList.value = filteredExerciseList.value.sort((a, b) => {
            const expiresA = new Date(a.listTries[0]?.expiresAt || 0).getTime();
            const expiresB = new Date(b.listTries[0]?.expiresAt || 0).getTime();
            return expiresA - expiresB;
          });
          break;

        case 3:
          filteredExerciseList.value = filteredExerciseList.value.sort((a, b) => a.name.localeCompare(b.name));
          break;

        default:
          break;
      }
    }

    const clearStatusFilters = () => {
      if (filterStatus.value && filterStatus.value.length) {
        filterStatus.value.forEach((item) => item.isActive = false);
        showSelectedFilters.value = false;
      }
    }

    const handleClose = () => {
      showModal.value = false;
    }

    const totalLists = ref({
      listsStarted: 0,
      listsNotStarted: 0,
      listsTotal: 0,
    })

    const handleLists = (concluded = false) => {
      let lists = !isFiltering.value ? exerciseList.value : filteredExerciseList.value;

      if (seeAllTheLists.value) {
        return lists;
      }

      if (concluded) {
        return lists.filter((it) => {
          it.status = getListStatus(it);
          return it.listTries.some((item => item.isFinished === true))
        })
      }

      let notFinishedList = lists.filter((it) => it.listTries.some((item) => item.isFinished != true))
      let notExpirationList = notFinishedList.filter((it) => !it.config?.endDate)
      let withExpirationList = notFinishedList
        .filter((it) => it.config?.endDate)
        .sort((a, b) => Date.parse(a.config?.endDate as string) - Date.parse(b.config?.endDate as string))

      return [ ...withExpirationList, ...notExpirationList  ]
    }

    const clearFiltersModalConfirmationHandler = (status = true) => {
      showClearFiltersConfirmModal.value = status;
    }

    const clearFilters = () => {
      clearStatusFilters();
      applyFilters();
    }

    const initExerciseListPage = async () => {
      show.value = true
      isLoading.value = true

      Promise.all([
        listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/shared', { page: 1, pageSize: 100 }, { signal: controller.signal }).then((res) => {
          exerciseList.value = res.data.data.data.filter((it) => {
            it.status = getListStatus(it);

            if (it.config?.professorName && it.userHasList && !filterListCreators.value.some(lc => lc.professorName === it.config?.professorName)) {
              filterListCreators.value.push({
                userId: it.userHasList.userId,
                professorName: it.config.professorName,
                active: it.userHasList.active,
                user: it.userHasList.user,
              });
            }

            if (it.config) {
              return it.config.status;
            }

            return it;
          })

          infinityScrollPage.value = Math.ceil(res.data.data.pageSize / infinityScrollPageSize.value)
          totalLists.value.listsTotal = res.data.data.totalElements
          key.value++
        }),
      ]).finally(() => {
        isLoading.value = false
        getListCustomStatus();
      })
    }

    const applyFilters = () => {
      filteredExerciseList.value = [...exerciseList.value];
      isFiltering.value = true;
      showSelectedFilters.value = true;

      // Filter by status
      if (filterStatus.value?.length) {
        filterStatus?.value.some((item) => {
          if (item.isActive) {
            filterByStatus();
          }
        })
      }

      // Filter by available answers
      if (filterAvailableAnswers.value?.isActive) {
        filterByAvailableAnswers();
      }

      // Filter by creator
      const filter = filterListCreators?.value?.filter((item) => {
        return item.active;
      }).map((item) => {
        return item.professorName;
      })

      if (filter.length) {
        filterByCreators(filter);
      }

      handleClose()
    }

    const filterByStatus = () => {
      const activeFilters: (number | undefined)[] = [];

      filterStatus.value?.forEach((it) => {
        if (it.isActive) {
          activeFilters.push(it.id)
        }
      });

      filteredExerciseList.value =  filteredExerciseList.value.filter((it) => {
        return (
          activeFilters.includes(it.status?.code) && it.status?.customStatus !== 'Prazo encerrado'
          || activeFilters.includes(4) && it.status?.customStatus === 'Prazo encerrado'
        )
      });
    }

    const filterByAvailableAnswers = () => {
      filteredExerciseList.value = filteredExerciseList.value.filter((item) => {
        return item.returnFeedback === true;
      });
    }

    const filterByCreators = (filter: string[]) => {
      filteredExerciseList.value = filteredExerciseList.value.filter((item) => {
        if (item?.config?.professorName) {
          return filter.includes(item?.config.professorName);
        }
      });
    }

    const submitSearch = () => {
      const data = searchList.value
      isLoading.value = false;

      if (!data) {
        isFiltering.value = false;
        return;
      }

      isFiltering.value = true;

      filteredExerciseList.value = exerciseList.value.filter((item) => {
        const lowerSearchTerm = data.toLowerCase();
        const lowerItem = item.name.toLowerCase();

        return lowerItem.includes(lowerSearchTerm);
      })
    }

    const fetchLists = async (index: number, done: (stop: boolean) => void) => {
      let stop = false
      try {
        const response = await listService.post<ResponseDTO<PagedDTO<SubjectInfoDTO>>>('/lists', {
          page: ++infinityScrollPage.value,
          pageSize: infinityScrollPageSize.value,
        })
        exerciseList.value.push(...response.data.data.data)
        if (response.data.data.currentPage + 1 > response.data.data.totalPages) stop = true
      } catch (error) {
        stop = true
      } finally {
        done(stop)
      }
    }

    const handleFilterModal = () => {
      showModal.value = !showModal.value
    }

    const getListCustomStatus = () => {
      exerciseList.value.map((it) => {
        const listTry = it.listTries[it.listTries.length - 1];

        if (listTry.duration?.toString() && it?.status?.code === listStatus.available.code) {
          it.status.customStatus = listTry.duration.toString() === '0' ? 'A fazer' : 'Iniciada';
        } else if (it?.status?.code === listStatus.expired.code) {
          it.status.customStatus = 'Prazo encerrado';
        }
      });

      exerciseListConcluded.value.map((it) => {
        if (it.status && !it.status?.customStatus) {
          it.status.customStatus = 'Concluída';
        }
      })
    }

    const getListStatus = (list: any) => {
      if (list.listTries.some(((item: any) => item.isFinished === true))) {
        if (list.returnFeedback) {
          return listStatus.resultsAvailable;
        }

        return listStatus.finished;
      }

      if (list?.config?.startDate) {
        if (list.config.startDate && new Date(list.config.startDate).toISOString() >= new Date().toISOString()) {
          return listStatus.comingSoon;
        }
      }

      if (list?.config?.endDate) {
        if (
          list.config.endDate === null
          || (list.config.endDate && new Date(list.config.endDate).toISOString() >= new Date().toISOString())
        ) {
          return listStatus.available;
        } else {
          return listStatus.expired;
        }
      } else {
        if (
          list.listTries.some(((item: any) => item.expiresAt === null))
          || (list.listTries[0].expiresAt && new Date(list.listTries[0].expiresAt).toISOString() >= new Date().toISOString())
        ) {
          return listStatus.available;
        } else {
          return listStatus.expired;
        }
      }
    }

    const handleSeeAllTheLists = () => {
      seeAllTheLists.value = !seeAllTheLists.value;
    }

    const handleShowListPreview = (value: boolean) => {
      showListPreview.value = value;
    }

    const handleSelectedList = (list: SubjectInfoDTO) => {
      selectedList.value = list;
    }

    const printList = ref<SubjectInfoDTO>();
    const handleShowPrintListModal = (value: boolean, list?: SubjectInfoDTO) => {
      showPrintListModal.value = value;
      printList.value = list;
    }

    const handleShowLimitTimeModal = async (value: boolean) => {
      showLimitTimeModal.value = value;
    }

    const lastTry = ref<ListTryDTO>();

    const startList = async () => {
      if (selectedList.value && selectedList.value.listTries) {
        const arraySorted = selectedList.value.listTries.map((it) => it).sort((a, b) => a.id - b.id)
        lastTry.value = arraySorted[arraySorted.length - 1]
      }

      if (lastTry.value && selectedList.value) {
        window.location.href = `/responder-lista/${selectedList.value.id}/${lastTry.value.id}`
      } else if (!lastTry.value && selectedList.value) {
        window.location.href = `/responder-lista/${selectedList.value.id}`
      }
    }

    provide('userDTO', getUser)
    provide('isExerciseList', isExerciseList)

    watch(
      getUser!,
      () => {
        if (!getUser?.value?.registrationCompleted) {
          isModalVisible.value = true
        } else show.value = true
      },
      { deep: true }
    )

    watch(searchList, () => {
      isLoading.value = true
      submitSearch()
    })

    watch(filterStatus, () => {
      showSelectedFilters.value = false;
    }, { deep: true })

    onMounted(initExerciseListPage)

    onUnmounted(() => {
      controller.abort()
    })

    return {
      isModalVisible,
      key,
      show,
      exerciseList,
      filteredExerciseList,
      exerciseListConcluded,
      isFiltering,
      isSearchInputExpanded,
      termsNeedAccept,
      searchList,
      submitSearch,
      foundFlag,
      notFound,
      isLoading,
      fetchLists,
      totalLists,
      showModal,
      handleClose,
      handleFilterModal,
      filterStatus,
      filterAvailableAnswers,
      filterListCreators,
      applyFilters,
      showSelectedFilters,
      clearFiltersModalConfirmationHandler,
      clearFilters,
      clearStatusFilters,
      seeAllTheLists,
      handleSeeAllTheLists,
      handleShowListPreview,
      showListPreview,
      handleSelectedList,
      selectedList,
      showPrintListModal,
      handleShowPrintListModal,
      showLimitTimeModal,
      handleShowLimitTimeModal,
      showClearFiltersConfirmModal,
      startList,
      printList,
      handleLists,
      isMobile: screen.isMobile,
      screen,
      isWhiteLabel: institutionConfig!.isWhiteLabel,
      handleSelectedOption,
      dropDownOptions,
      DefaultTheme,
      FilterLinesIcon,
      ReturnIcon,
      RecycleBinIcon,
    }
  },
})
