
import DeadlineCalendarIcon from "@/components/Icons/default/redesign/sharedList/DeadlineCalendarIcon.vue";
import DefaultCalendarIcon from "@/components/Icons/default/redesign/sharedList/DefaultCalendarIcon.vue";
import DisabledCalendarIcon from "@/components/Icons/default/redesign/sharedList/DisabledCalendarIcon.vue";
import ErrorCalendarIcon from "@/components/Icons/default/redesign/sharedList/ErrorCalendarIcon.vue";
import LockedListIcon from "@/components/Icons/default/redesign/sharedList/LockedListIcon.vue";
import ReachedDeadlineCalendarIcon from "@/components/Icons/default/redesign/sharedList/ReachedDeadlineCalendarIcon.vue";
import SuccessCalendarIcon from "@/components/Icons/default/redesign/sharedList/SuccessCalendarIcon.vue";
import WarningCalendarIcon from "@/components/Icons/default/redesign/sharedList/WarningCalendarIcon.vue";
import DefaultTheme from "@/theme/defaultTheme";
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import { ListsInfosType } from "../../../dtos/ListRetrieveDTO";
import { listStatus } from '../../../enum/listStatusEnum';

export default defineComponent({
  name: 'CardDeadline',
  props: {
    subjectInfo: {
      type: Object as PropType<ListsInfosType>,
      required: true,
    },
    displayCardsInline: Boolean,
    listPreview: Boolean
  },
  computed: {
    deadLineIconComponent() {
      switch (this.deadline.customClass) {
        case 'success':
          return SuccessCalendarIcon;
        case 'error':
          return ErrorCalendarIcon;
        case 'warning':
          return WarningCalendarIcon;
        case 'disabled':
          return DisabledCalendarIcon;
        default:
          return DefaultCalendarIcon;
      }
    },
  },
  components: {
    DeadlineCalendarIcon,
    ReachedDeadlineCalendarIcon,
    LockedListIcon
  },
  setup(props) {
    const statusCode = ref(props.subjectInfo.status?.code);
    const expiracyDate = ref(props.subjectInfo.config?.endDate ? props.subjectInfo.config?.endDate : props.subjectInfo.listTries[0].expiresAt);
    const listTries = reactive([...props.subjectInfo.listTries]);

    const deadline = reactive({
      text: '',
      customClass: 'default',
      description: '',
      time: '',
      date: '',
      expires: expiracyDate.value ? true : false,
    })

    const formatDeadline = (date: Date | string) => {
      const today = new Date();

      const yesterday = new Date(); 
      yesterday.setDate(new Date().getDate() - 1);

      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);

      const targetDate = new Date(date);
      const diffTime = today.getTime() - targetDate.getTime();
      const diffDays = Math.abs(diffTime) / (1000 * 3600 * 24);

      if (new Date(date).toDateString() === new Date().toDateString()) {
        return 'Hoje';
      } else if (targetDate.toDateString() === yesterday.toDateString()) {
        return 'Ontem';
      } else if (targetDate.toDateString() === tomorrow.toDateString()) {
        return 'Amanhã';
      }

      if(diffDays > 4) {
        const day = targetDate.getDate().toString().padStart(2, '0');
        const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
        const year = targetDate.getFullYear().toString().slice(-2);
        
        return `${day}/${month}/${year}`;
      } else {
        let formatted = targetDate.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0];
        return formatted.charAt(0).toUpperCase() + formatted.slice(1);
      }
    }

    const getDeadline = () => {
      switch(statusCode.value) {
        case (listStatus.resultsAvailable.code):
        case (listStatus.finished.code): {
          deadline.text = 'Concluída'
          deadline.customClass = 'success'

          const finishedAt = listTries[listTries.length - 1].updatedAt;
          deadline.description = formatDeadline(finishedAt);

          deadline.expires = listTries[listTries.length - 1].updatedAt ? true : false;
          deadline.time = new Date(finishedAt).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
          deadline.date = new Date(finishedAt).toLocaleDateString([], { month: '2-digit', day: '2-digit'});

          break;
        };
        case (listStatus.expired.code): {
          deadline.text = 'Encerrou'
          deadline.customClass = 'error'

          if (expiracyDate.value) {
            deadline.description = formatDeadline(new Date(expiracyDate.value))
            deadline.time = new Date(expiracyDate.value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            deadline.date = new Date(expiracyDate.value).toLocaleDateString([], { month: '2-digit', day: '2-digit'});

            break;
          }

          break;
        };
        case (listStatus.comingSoon.code): {
          deadline.text = 'Início'
          deadline.customClass = 'disabled'

          const startDate = props?.subjectInfo?.config?.startDate ? props.subjectInfo.config.startDate : '';
          deadline.description = formatDeadline(new Date(startDate))
          deadline.time = new Date(startDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
          deadline.date = new Date(startDate).toLocaleDateString([], { month: '2-digit', day: '2-digit'});

          break;
        }
        case (listStatus.available.code): {
          deadline.text = 'Encerra'
          deadline.customClass = 'default'

          if (expiracyDate.value) {
            if (
              new Date(expiracyDate.value).getFullYear() === new Date().getFullYear() &&
              new Date(expiracyDate.value).getMonth() === new Date().getMonth() &&
              new Date(expiracyDate.value).getDate() === new Date().getDate()
            ) {
              deadline.customClass = 'warning'
              deadline.description = 'Hoje'
            }
            deadline.description = formatDeadline(new Date(expiracyDate.value))
            deadline.time = new Date(expiracyDate.value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            deadline.date = new Date(expiracyDate.value).toLocaleDateString([], { month: '2-digit', day: '2-digit'});
          }
          break;
        };
      }
    }

    onMounted(getDeadline)

    return {
      deadline,
      statusCode,
      listStatus,
      DefaultTheme
    }
  }
});

