
import ContentModalRegisterUsers from '@/components/Application/planSchoolPortal/modals/modalsUsersComponent/ContentModalRegisterUsers.vue'
import PaginationTableComponent from '@/components/Application/planSchoolPortal/mountTableComponent/components/PaginationTableComponent.vue'
import TablesComponentUsers from '@/components/Application/planSchoolPortal/mountTableComponent/TablesComponent.vue'
import PazzeiBtn from '@/components/Generics/PazzeiBtn.vue'
import PazzeiInput from '@/components/Generics/PazzeiInput.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import Modal from '@/components/Library/Modal/index.vue'
import ModalConfirmation from '@/components/Library/ModalConfirmation/index.vue'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import groupsService from '@/services/groups'
import usersService from '@/services/users'
import { EntitiesUser, ToggleResourceStatusType, User } from '@/types/UsersType'

import AddIcon from '@/components/Icons/default/AddIcon.vue'
import ArrowBackIcon from '@/components/Icons/default/ArrowBackIcon.vue'
import BlockSchoolIcon from '@/components/Icons/default/BlockSchoolIcon.vue'
import EditRegisterIcon from '@/components/Icons/default/EditRegisterIcon.vue'
import EstatisticsIcon from '@/components/Icons/default/EstatisticsIcon.vue'
import ResendEmailIcon from '@/components/Icons/default/ResendEmailIcon.vue'
import SearchIcon from '@/components/Icons/default/SearchIcon.vue'
import UnBlockSchoolIcon from '@/components/Icons/default/UnBlockSchoolIcon.vue'
import DefaultTheme from '@/theme/defaultTheme'
import { columnsUserInstitution, filtersUserInstitution } from '@/utils/hardCoded/planSchool/users/usersInstitutions'
import { getSubdomainFromLocalStorage } from '@/utils/subdomain'
import debounce from 'quasar/src/utils/debounce.js';import useQuasar from 'quasar/src/composables/use-quasar.js';
import { defineComponent, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import authService from '../services/authService'
import LoginIcon from '@/components/Icons/default/LoginIcon.vue'
import AccessHelper from '@/helpers/AccessHelper'

export default defineComponent({
  name: 'ViewStudentsInstitutionsPage',
  components: { GenericLayout, MainLayoutWrapper, PazzeiBtn, PazzeiInput, Modal, TablesComponentUsers, PaginationTableComponent, ContentModalRegisterUsers, ModalConfirmation, ArrowBackIcon, SearchIcon },

  setup() {
    const isSearchInputExpanded = ref(true)
    const isModalOpen = ref(false)
    const isEditing = ref(false)
    const isFilterSelected = ref(false)
    const sizeColumnsUsers = ref('1fr / 0.7fr 2.5fr 1.3fr 2.5fr 1fr 1.2fr 1fr 0.25fr')
    const router = useRouter()
    const $q = useQuasar()
    const route = useRoute()
    const institutionId = Number(route.params.id)
    const infoUser = 'Após a confirmação, ele perderá o acesso à plataforma.'
    const isModalConfirmOpen = ref(false)
    const dataTableRow = ref<any>()
    const rows = ref<User[]>([])
    const innerWidth = ref(window.innerWidth)
    const width = ref('66.875em')
    const height = ref('36.25em')
    const userId = ref<string>('')
    const currentPage = ref(1)
    const pageSize = ref(20)
    const isLoading = ref(false)
    const sizeLoading = ref('60px')
    const totalElements = ref(0)
    const filterId = ref<string | undefined>('')
    const filterName = ref<string | undefined>('')
    const generalFilter = ref<string | undefined>('')
    const filterTelephone = ref<string | undefined>('')
    const filterEmail = ref<string | undefined>('')
    const filterDate = ref<string | undefined>('')
    const filterIsActive = ref<boolean | string | undefined>('')
    const filterRole = ref<string | undefined>('')
    const isActive = ref<boolean | undefined>(undefined)
    const institutionName = ref('')
    const heightLoading = ref('8.2em')
    const heightTable = ref('47.5em')
    const getResponse = ref<User[]>([])
    const previousPageSize = ref(20)
    const previousCurrentPage = ref(1)
    const sizeEditLoading = ref('3em')
    const registrationCompleted = ref<boolean | undefined>(false)
    const userPermission = ref(false)
    const permissions = authService.getPermissions()?.permissions
    userPermission.value = permissions?.includes('all:users:users:manage') ?? false

    const openModal = () => ((isModalOpen.value = true), (isEditing.value = false))
    const openEditModal = () => ((isModalOpen.value = true), (isEditing.value = true))
    const closeModal = () => (isModalOpen.value = false)
    const openFilterSelected = () => (isFilterSelected.value = !isFilterSelected.value)
    const setNewTab = () => {
      innerWidth.value = window.innerWidth
    }
    window.addEventListener('resize', setNewTab)
    const handleModalConfirm = () => {
      if (dataTableRow.value && isActive.value) {
        isModalConfirmOpen.value = true
      } else {
        toggleResourceStatusUser()
      }
    }

    onMounted(() => {
      if (innerWidth.value <= 768) {
        width.value = '90%'
        height.value = '24em'
        heightTable.value = '52vh'
        sizeColumnsUsers.value = '1fr / 0.4fr 1.5fr 0.7fr 1.5fr 0.7fr 1fr 0.7fr 0.25fr'
        sizeLoading.value = '2.1875em'
        heightLoading.value = '9.5em'
        sizeEditLoading.value = '2em'
      }
      getNameInstitution()
      getInstitutionUsers()
    })

    const handleFiltersApplied = (filterValues: User) => {
      filterId.value = String(filterValues.readableId)
      filterName.value = filterValues.name
      filterTelephone.value = filterValues.telephone
      filterEmail.value = filterValues.email
      filterDate.value = filterValues.createdAt
      filterIsActive.value = filterValues.isActive
      filterRole.value = filterValues.roleDescription

      debouncedGetInstitutionUsers()
    }

    const handlePageSizeChange = (selectedPageSize: number) => {
      if (selectedPageSize !== previousPageSize.value) {
        pageSize.value = selectedPageSize
        getInstitutionUsers()
        previousPageSize.value = selectedPageSize
      }
    }

    const handleCurrentPage = (selectedCurrentPage: number) => {
      if (selectedCurrentPage !== previousCurrentPage.value) {
        currentPage.value = selectedCurrentPage
        getInstitutionUsers()
        previousCurrentPage.value = selectedCurrentPage
      }
    }

    const notification = (success: boolean, message: string) => {
      if (success) {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      } else {
        $q.notify({
          textColor: 'grey-1',
          message: message,
          color: 'red',
          position: 'top',
          classes: 'notify',
        })
      }
    }

    const getInstitutionUsers = async () => {
      const searchByName = generalFilter.value !== '' ? { name: generalFilter.value } : {}
      const searchByOtherField = filterName.value !== '' ? { name: filterName.value } : {}

      const requestBody = {
        page: currentPage.value,
        pageSize: pageSize.value,
        filter: {
          institution: institutionId,
        },
        search: {
          ...searchByName,
          ...searchByOtherField,
          readableId: filterId.value !== '' ? Number(filterId.value) : undefined,
          email: filterEmail.value !== '' ? filterEmail.value : undefined,
          telephone: filterTelephone.value !== '' ? filterTelephone.value : undefined,
          roleDescription: filterRole.value !== '' ? filterRole.value : undefined,
          isActive: filterIsActive.value !== '' ? (filterIsActive.value === 'Ativo' ? true : false) : undefined,
          date: filterDate.value !== '' ? filterDate.value : undefined,
        },
      }

      isLoading.value = true
      try {
        const response = await usersService.post<ResponseDTO<EntitiesUser>>('/filter-users', requestBody)
        getResponse.value = response.data.data.entities
        rows.value = getResponse.value.map((row: User) => {
          return {
            id: row.id,
            readableId: row.readableId,
            name: `${row.name} ${row.lastName !== null ? row.lastName : ''}`,
            email: row.email,
            telephone: row.telephone,
            createdAt: row.createdAt,
            roleDescription: row.roleDescription,
            isActive: row.isActive,
            isRegistrationCompleted: row.isRegistrationCompleted,
          }
        })

        totalElements.value = response.data.data.totalElements
      } catch (error) {
        notification(false, 'Erro ao buscar dados')
      } finally {
        isLoading.value = false
      }
    }

    const getNameInstitution = async () => {
      const { data: infos } = await groupsService.get('/institutions/single', { params: { id: institutionId } })
      institutionName.value = infos.data.name
    }

    const debouncedGetInstitutionUsers = debounce(getInstitutionUsers, 500)

    watch(generalFilter, () => {
      currentPage.value = 1
      debouncedGetInstitutionUsers()
    })

    watch(isFilterSelected, () => {
      generalFilter.value = ''
      currentPage.value = 1
      if (
        filterId.value !== '' &&
        filterName.value !== '' &&
        filterTelephone.value !== '' &&
        filterEmail.value !== '' &&
        filterRole.value !== '' &&
        filterIsActive.value !== '' &&
        filterDate.value !== ''
      ) {
        debouncedGetInstitutionUsers()
      }
    })

    const iconProps = {
      fill: DefaultTheme().colors.pinkColor,
      stroke: DefaultTheme().colors.pinkColor,
      size: 20,
    }

    const optionsMenu = ref([
      { label: 'Editar Cadastro', icon: EditRegisterIcon, iconProps, action: () => openEditModal?.() },
      { label: '', icon: '', action: () => handleModalConfirm?.() },
      { label: 'Reenviar E-mail', icon: ResendEmailIcon, iconProps, action: () => resendEmail?.() },
      { label: 'Ver estatísticas', icon: EstatisticsIcon, iconProps, action: () => '', disabled: true },      
      { label: 'Simular Acesso', icon: LoginIcon, iconProps, action: () => simulateAccess(),},
    ])

    const handleValueUpdated = (value: User) => {
      dataTableRow.value = value
      userId.value = value.id
      isActive.value = value.isActive
      registrationCompleted.value = value.isRegistrationCompleted

      if (registrationCompleted.value) {
        const reenviarEmailIndex = optionsMenu.value.findIndex((option) => option.label === 'Reenviar E-mail')
        if (reenviarEmailIndex !== -1) {
          optionsMenu.value.splice(reenviarEmailIndex, 1)
        }
      } else {
        const reenviarEmailIndex = optionsMenu.value.findIndex((option) => option.label === 'Reenviar E-mail')
        if (reenviarEmailIndex === -1) {
          optionsMenu.value.push({ label: 'Reenviar E-mail', icon:ResendEmailIcon, iconProps, action: () => resendEmail?.() })
        }
      }

      if (isActive.value !== undefined) {
        optionsMenu.value[1].label = `${isActive.value ? 'Inativar' : 'Ativar'} Usuário`
        optionsMenu.value[1].icon =isActive.value ? BlockSchoolIcon : UnBlockSchoolIcon
        optionsMenu.value[1].iconProps = iconProps
      }
    }

    provide('heightLoading', heightLoading)
    provide('emitNewValue', handleFiltersApplied)
    provide('openFilterSelected', openFilterSelected)
    provide('isFilterSelected', isFilterSelected)
    provide('emitValueToParent', handleValueUpdated)
    provide('optionsMenu', optionsMenu)
    provide('filters', filtersUserInstitution)

    const toggleResourceStatusUser = async () => {
      const data = { userIds: [userId.value] }
      try {
        if (isActive.value) {
          await usersService.put<Promise<ToggleResourceStatusType>>('/deactivate', data)
        } else {
          await usersService.put<Promise<ToggleResourceStatusType>>('/activate', data)
        }
        notification(true, `Usuário ${!isActive.value ? 'ativado' : 'inativado'} com sucesso`)

        getInstitutionUsers()
      } catch (error) {
        notification(false, 'Erro ao alternar o status do usuário')
      }
    }

    const simulateAccess = async () => {
      await AccessHelper(userId.value)
      
      .then(() => {
        $q.notify({
          textColor: 'grey-1',
          message: `Criando simulação de acesso...`,
          color: 'green',
          position: 'top',
          classes: 'notify',
        })
      })
      
      .catch((error) => { 
        $q.notify({
          textColor: 'grey-1',
          message: `Falha ao simular acesso. ${error.message}`,
          color: 'red',
          position: 'top',
          classes: 'notify',
        }) 
      })

    }

    const resendEmail = async () => {
      const subdomain = getSubdomainFromLocalStorage()
      try {
        await usersService.patch('resend', { id: userId.value, subdomain })
        notification(true, 'Solicitação de reenvio de e-mail realizada com sucesso')
      } catch (error) {
        notification(false, 'Falha na solicitação de reenvio de e-mail')
      }
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setNewTab)
    })

    return {
      isSearchInputExpanded,
      isModalOpen,
      openModal,
      innerWidth,
      width,
      height,
      closeModal,
      isEditing,
      openEditModal,
      isFilterSelected,
      openFilterSelected,
      rows,
      columnsUserInstitution,
      filtersUserInstitution,
      sizeColumnsUsers,
      router,
      isModalConfirmOpen,
      infoUser,
      userPermission,
      toggleResourceStatusUser,
      dataTableRow,
      userId,
      getInstitutionUsers,
      handlePageSizeChange,
      handleCurrentPage,
      isLoading,
      sizeLoading,
      totalElements,
      handleFiltersApplied,
      generalFilter,
      institutionName,
      institutionId,
      heightTable,
      sizeEditLoading,
      DefaultTheme,
      AddIcon
    }
  },
})
