
import { AlternativesSelectorModelValueType } from '@/components/Application/alternativesSelector/types/AlternativesSelectorModelValueType'
import { AlternativesSetType } from '@/components/Application/alternativesSelector/types/AlternativesSet'
import { formatDataToSend, weighting } from '@/components/Application/enemModulePage/WeightingFunctions'
import { AreasTitles, SelectedAreasToWeighting } from '@/components/Application/enemModulePage/enemModule/types'
import PazzeiModalAlert from '@/components/GenericModals/PazzeiModalAlert/PazzeiModalAlert.vue'
import PazzeiModalConfirmation from '@/components/GenericModals/PazzeiModalConfirmation/PazzeiModalConfirmation.vue'
import PazzeiModal from '@/components/Generics/PazzeiModal.vue'
import AlertIcon from '@/components/Icons/default/redesign/sharedList/AlertIcon.vue'
import AlertIconRedIcon from '@/components/Icons/default/redesign/sharedList/AlertIconRedIcon.vue'
import GenericLayout from '@/components/Layouts/GenericLayout.vue'
import MainLayoutSubWrapper from '@/components/Layouts/MainLayoutSubWrapper.vue'
import MainLayoutWrapper from '@/components/Layouts/MainLayoutWrapper.vue'
import QuestionWrapper from '@/components/Layouts/Redesign/QuestionWrapper.vue'
import QuestionsCheckWrapper from '@/components/Layouts/Redesign/QuestionsCheckWrapper.vue'
import Button from '@/components/Library/Button/index.vue'
import Modal from '@/components/Library/Modal/index.vue'
import MultipleActions from '@/components/Library/ModalConfirmation/MultipleActions.vue'
import StopWatch from '@/components/Library/StopWatch/index.vue'
import TimerCountdown from '@/components/Library/TimerCountdown/index.vue'
import ListCurrentTryResume from '@/components/ListCurrentTryResume/ListCurrentTryResume.vue'
import ListHeader from '@/components/ListHeader/ListHeader.vue'
import ModalCongrats from '@/components/ListsFinishedModal/ModalCongrats.vue'
import { useScreenSize } from '@/composables/screen-size'
import { ListTagKeyEnum } from '@/dtos/CreateListPage/ListTagDTO'
import { CreateUpdateAnswerDTO } from '@/dtos/CreateUpdateAnswerDTO'
import { HandleErrorAxiosResponseDTO } from '@/dtos/HandleErrorAxiosResponseDTO'
import { GetOneListResponseDTO } from '@/dtos/ResponseDTOs/GetOneListResponseDTO'
import { listHasQuestionDTO } from '@/dtos/ResponseDTOs/ListHasQuestionDTO'
import { ListTryDTO } from '@/dtos/ResponseDTOs/ListTryDTO'
import { QuestionDTO } from '@/dtos/ResponseDTOs/QuestionDTO'
import { ResponseDTO } from '@/dtos/ResponseDTOs/ResponseDTO'
import { TagDTO } from '@/dtos/ResponseDTOs/TagDTO'
import { UserResponseDTO } from '@/dtos/ResponseDTOs/UserResponseDTO'
import { SubjectInfoDTO } from '@/dtos/SubjectInfoDTO'
import { MessagesOnModalConfirmationDTO } from '@/dtos/answerListPage/MessagesOnModalConfirmationDTO'
import listsService from '@/services/list'
import questionsService from '@/services/question'
import DefaultTheme from '@/theme/defaultTheme'
import { QuestionCacheType } from '@/types/QuestionCacheType'
import { SingleTryType } from '@/types/SingleTryType'
import { largeAreas } from '@/utils/hardCoded/enemModule/enemModule'
import { AxiosError } from 'axios'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { computed, defineComponent, onMounted, onUnmounted, provide, reactive, ref, toRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import StopwatchClockIcon from '@/components/Icons/default/redesign/sharedList/StopwatchClockIcon.vue'

export default defineComponent({
  name: 'AnswerListPage',
  components: {
    ListCurrentTryResume,
    PazzeiModalAlert,
    PazzeiModalConfirmation,
    GenericLayout,
    MainLayoutWrapper,
    MainLayoutSubWrapper,
    QuestionWrapper,
    PazzeiModal,
    ModalCongrats,
    Button,
    StopWatch,
    ListHeader,
    Modal,
    MultipleActions,
    QuestionsCheckWrapper,
    TimerCountdown,
  },
  setup() {
    const $q = useQuasar()
    const controller = new AbortController()
    const alternativesModel = ref<AlternativesSelectorModelValueType>()
    const router = useRouter()
    const { id, tryId } = useRoute().params
    const showFinishFeedback = ref(false)
    const listLargeAreas = ref<{ areas: Array<string>; isListEnemModuleAndAtYourPace: boolean }>({ areas: [], isListEnemModuleAndAtYourPace: false })
    const exitListButton = reactive<{ takeBreak: boolean, label: string }>({
      takeBreak: false,
      label: 'Sair da lista'
    });
    const listId: string = id.toString();

    const modalStatus = reactive({
      congrats: false,
      forgottenQuestions: false,
      warningListClose: false,
      warningListBreak: false,
      alertListIncomplete: false,
      alertListTimeEnd: false,
      alertListDeadlineEnd: false,
    });

    const seriesCount = computed(() => {
      if (listInfos.value.totalQuestions === 0) return 0

      return Math.ceil(listInfos.value.totalQuestions / (isListEnemModuleAndAtYourPace.value ? 15 : 45))
    })

    const currentSerie = ref(1)

    const questionsNotAnswered = ref<Array<{ order: number; questionId: number; isDoubt: boolean; isAnswered: boolean; questionBelongingArea?: string }>>([])
    const correctAlternatives = ref<Array<number>>([])
    const finalButtonsRef = ref<HTMLDivElement | null>(null)

    const skippingQuestions = ref(false)
    const lastQuestion = ref(false)

    const questionType = ref('')
    const listTryDTO = ref<ListTryDTO>()
    const totalResponses = ref(0);

    const listInfos = ref<GetOneListResponseDTO>({
      name: '',
      totalQuestions: 0,
      listHasQuestions: [],
      instantFeedback: false,
      isTest: false,
      returnFeedback: false,
      tags: [],
    })

    const listTry = ref<SingleTryType>();
    const progress = ref(0);
    const currentQuestionId = ref<number>(0)
    const questionNumber = ref<number>(1)
    const questionNumberInput = ref<string>(String(questionNumber.value))
    let questionCache = Array<QuestionCacheType>()
    const topicTag = ref<Array<TagDTO>>()
    const isListEnemModule = ref(false)
    const isListEnemModuleAndAtYourPace = ref(false)
    const areaName = ref<string>('')
    const isDoubt = ref(false)
    const countAreas = ref(0)
    const isLoadingDoubt = ref(true)
    const isLoadingQuestion = ref(true)
    const listTimeElapsed = ref<number>()
    const currentAreaIndex = ref(1);

    const mainCtaDisabled = ref(false);

    const screen = useScreenSize();

    const isDoubtLabelButton = () => {
      if (isDoubt.value) return 'Desmarcar como dúvida'
      return 'Marcar como dúvida'
    }

    const checkResponsesAlternativesModel = ref<AlternativesSelectorModelValueType[]>([]);

    const handleSaveQuestion = async (currentAlternativesModel: AlternativesSelectorModelValueType) => {
      if (currentAlternativesModel) {
        try {
          await saveResponse(currentAlternativesModel)
        } finally {
          isLoadingQuestion.value = false
        }
      }
    }

    const handleMarkAsDoubt = async () => {
      isLoadingDoubt.value = true
      try {
        isDoubt.value = !isDoubt.value
        if (alternativesModel.value) await saveResponse(alternativesModel.value)
        handleObjectQuestionsNotAnswered(currentQuestionId.value, isDoubt.value)
      } finally {
        isLoadingDoubt.value = false
      }
    }

    const openMultipleActionsModal = ref(false)

    const closeMultipleActionsModal = () => (openMultipleActionsModal.value = false)

    const messagesOnModal = ref<MessagesOnModalConfirmationDTO>({
      title: '',
      info: '',
      auxiliaryInfo: '',
      arrayOfButton: [],
    })

    const initalConfirmationMessages = () => {
      openMultipleActionsModal.value = true
      messagesOnModal.value.title = 'Enem no seu ritmo'
      messagesOnModal.value.auxiliaryInfo = 'Ah, e não se preocupe que você pode fazer pausas durante o teste, beleza?'
      messagesOnModal.value.arrayOfButton = [{ label: 'Entendido, bora lá!', variant: 'primary-bold', click: closeMultipleActionsModal, position: 'top' }]

      if (countAreas.value > 1) {
        messagesOnModal.value.info =
          'Separamos séries de 15 questões por área do conhecimento para você treinar no seu ritmo. Ao final de cada série, você poderá continuar com mais 15 questões, seguir para a próxima área de conhecimento ou parar e conferir seus resultados.'
      } else {
        messagesOnModal.value.info =
          'Separamos séries de 15 questões para você treinar no seu ritmo. Ao final de cada série, você poderá continuar com mais 15 questões ou parar e conferir seus resultados.'
      }
    }

    const nextQuestionConfirmationMessages = () => {
      messagesOnModal.value.title = 'Parabéns, você concluiu uma série de questões!'
      messagesOnModal.value.auxiliaryInfo = ''
      if (countAreas.value > 1 && (parseInt(localStorage.getItem('currentAreaIndex') || "0") < countAreas.value)) {
        messagesOnModal.value.info = 'Como quer prosseguir com o teste? Você pode resolver questões da próxima área do conhecimento, resolver uma nova série da mesma área ou finalizar o simulado.'
        messagesOnModal.value.arrayOfButton = [
          { label: 'Próxima Área do Conhecimento', variant: 'primary-bold', click: () => addQuestionsOnList(true), position: 'top', width: '21em' },
          { label: 'Resolver nova série', variant: 'secondary-bold', click: () => addQuestionsOnList(false), position: 'bottom', width: '12.1em' },
          { label: 'Finalizar', variant: 'secondary-bold', click: finalizationListConfirmation, position: 'bottom', width: '12.1em' },
        ]
      } else {
        messagesOnModal.value.info = 'Quer continuar com mais uma série de 15 questões ou finalizar o teste?'
        messagesOnModal.value.arrayOfButton = [
          { label: 'Resolver nova série', variant: 'secondary-bold', click: () => addQuestionsOnList(false), position: 'bottom', width: '12.1em' },
          { label: 'Finalizar', variant: 'primary-bold', click: () => finalizeList(true), position: 'bottom', width: '12.1em' },
        ]
      }
      openMultipleActionsModal.value = true
    }

    const addLoadingOnButtons = (goNextArea: boolean) => {
      if (countAreas.value > 1 && (parseInt(localStorage.getItem('currentAreaIndex') || "0") < countAreas.value)) {
        if (goNextArea)
          messagesOnModal.value.arrayOfButton = [
            { label: 'Próxima Área do Conhecimento', variant: 'primary-bold', click: () => addQuestionsOnList(true), position: 'top', width: '21em', loading: true },
            { label: 'Resolver nova série', variant: 'secondary-bold', click: () => addQuestionsOnList(false), position: 'bottom', width: '12em', disabled: true },
            { label: 'Finalizar', variant: 'secondary-bold', click: finalizationListConfirmation, position: 'bottom', width: '12em', disabled: true },
          ]
        else {
          messagesOnModal.value.arrayOfButton = [
            { label: 'Próxima Área do Conhecimento', variant: 'primary-bold', click: () => addQuestionsOnList(true), position: 'top', width: '21em', disabled: true },
            { label: 'Resolver nova série', variant: 'secondary-bold', click: () => addQuestionsOnList(false), position: 'bottom', width: '12em', loading: true },
            { label: 'Finalizar', variant: 'secondary-bold', click: finalizationListConfirmation, position: 'bottom', width: '12em', disabled: true },
          ]
        }
      } else {
        messagesOnModal.value.arrayOfButton = [
          { label: 'Resolver nova série', variant: 'secondary-bold', click: () => addQuestionsOnList(false), position: 'bottom', width: '12em', loading: true },
          { label: 'Finalizar', variant: 'secondary-bold', click: finalizationListConfirmation, position: 'bottom', width: '12em', disabled: true },
        ]
      }
    }

    const finalizationListConfirmation = () => {
      messagesOnModal.value.title = 'Tem certeza que deseja parar?'
      messagesOnModal.value.info = 'Você ainda tem outra área do conhecimento para responder antes de finalizar. Se quiser parar agora, vamos desconsiderar a próxima área.'
      messagesOnModal.value.arrayOfButton = [
        { label: 'Continuar resolvendo', variant: 'secondary-bold', click: closeMultipleActionsModal, position: 'bottom' },
        { label: 'Finalizar', variant: 'primary-bold', click: () => finalizeList(true), position: 'bottom' },
      ]
      openMultipleActionsModal.value = true
    }

    const questionsToAddOnList = ref<Array<number>>([])

    const addQuestionsOnList = async (goNextArea: boolean) => {
      addLoadingOnButtons(goNextArea)

      const type = listInfos.value.tags?.find((it) => it.key === ListTagKeyEnum.CATEGORY)?.value ?? 'no seu ritmo'
      let areasTitle: Array<AreasTitles>

      if (goNextArea) {
        currentAreaIndex.value++;
        localStorage.setItem('currentAreaIndex', `${currentAreaIndex.value}`)
        const areasOnList = listInfos.value.tags?.filter((it) => it.key === ListTagKeyEnum.AREA)
        const areasNames = areasOnList?.map((it) => it.value)

        const largeAreasOnOrder = largeAreas.filter((it) => areasNames?.includes(it.title))
        largeAreasOnOrder.sort((a, b) => a.order - b.order)

        const currentArea = largeAreas.find((it) => it.title === areaName.value)
        const nextArea = largeAreasOnOrder.find((it) => it.order > currentArea!.order)

        areasTitle = [
          {
            title: nextArea!.title,
          },
        ]
      } else {
        areasTitle = [
          {
            title: areaName.value,
          },
        ]
      }

      const selectedAreasToWeighting = new SelectedAreasToWeighting(areasTitle, type)

      let shuffle = false
      let showAlreadyAnsweredQuestion = true
      let isSpanishForeignLanguageSelected = false
      listInfos.value.tags?.forEach((it) => {
        if (it.key === ListTagKeyEnum.SHUFFLE) shuffle = it.value === 'true'
        if (it.key === ListTagKeyEnum.ALREADY_ANSWERED) showAlreadyAnsweredQuestion = it.value === 'true'
        if (it.key === ListTagKeyEnum.CHOSEN_LANGUAGE) isSpanishForeignLanguageSelected = it.value === 'Espanhol'
      })

      const subjects = await weighting(selectedAreasToWeighting, isSpanishForeignLanguageSelected)

      try {
        questionsToAddOnList.value = await formatDataToSend(shuffle, showAlreadyAnsweredQuestion, subjects, +id)
        const dataToEditList = {
          id: +id,
          questionIds: questionsToAddOnList.value,
        }

        await listsService.patch<ResponseDTO<SubjectInfoDTO>>('/', dataToEditList)

        questionCache = []
        initAnswerPage(true)
      } catch (err) {
        if (err instanceof AxiosError) {
          const { error } = new HandleErrorAxiosResponseDTO(err)

          $q.notify({
            textColor: 'grey-1',
            message: error.userMessage,
            color: 'red',
            position: 'top',
            classes: 'notify',
          })
        }
      } finally {
        openMultipleActionsModal.value = false
      }
    }

    const getAnswersOnInit = (questionId: number, userResponses: Array<UserResponseDTO>) => {
      const questionAnswer = userResponses.find((ur) => ur.questionId === questionId)
      let answers: Partial<{ alternativeIds: Array<number>; isDoubt: boolean, discardedAlternatives: Array<number> }> = {}

      if (questionAnswer) {
        let answeredAlternatives = Array<number>()
        let discardedAlternatives = Array<number>()

        if (questionAnswer.responseHasAlternatives) {
          questionAnswer.responseHasAlternatives.forEach((item) => {
            if (item.alternativeId) {
              answeredAlternatives.push(item.alternativeId)
            }
            if (item.discardedAlternatives?.length) {
              discardedAlternatives.push(...item.discardedAlternatives);
            }
          })
        }

        answers = {
          alternativeIds: answeredAlternatives ?? [],
          isDoubt: questionAnswer.isDoubt ?? false,
          discardedAlternatives: discardedAlternatives ?? [],
        }

        alternativesModel.value = {
          questionId,
          alternatives: answeredAlternatives,
          discardedAlternatives,
        }
      }

      return answers
    }

    const updateCache = (questionId: number, userResponses: Array<AlternativesSetType>, discardedAlternatives?: Array<AlternativesSetType>) => {
      const findQuestion = questionCache.find((qc) => qc.questionId === questionId)

      if (findQuestion) {
        const responses = userResponses as Array<number>
        const discarded = discardedAlternatives as Array<number>

        findQuestion.isAnswered = responses.length ? true : false
        findQuestion.userResponses = responses.length ? responses : []
        findQuestion.discardedAlternatives = discarded.length ? discarded : []
      }
    }

    const updateQuestionView = (questionNumberView: number, questionIndex?: null | number) => {
      let auxQuestionNumber = questionNumberView

      if (isListEnemModule.value)
        if (isListEnemModuleAndAtYourPace.value) {
          auxQuestionNumber = questionNumberView % 15 === 0 ? 15 : questionNumberView % 15

          if (questionIndex) currentSerie.value = Math.ceil((questionIndex + 1) / 15)
          else currentSerie.value = Math.ceil(questionNumberView / 15)
        }

      questionNumberInput.value = String(auxQuestionNumber)
      questionNumber.value = auxQuestionNumber
    }

    const changeQuestion = async (questionNumberChanged: number, questionIndex?: null | number) => {
      skippingQuestions.value = true
      let auxQuestionNumber = questionNumberChanged

      if (questionNumberChanged > listInfos.value.totalQuestions) auxQuestionNumber = listInfos.value.totalQuestions
      else if (questionNumberChanged < 1) auxQuestionNumber = 1

      if (alternativesModel.value) {
        updateCache(currentQuestionId.value, toRaw(alternativesModel.value.alternatives), toRaw(alternativesModel.value.discardedAlternatives))

        if (!listInfos.value.instantFeedback || questionType.value === 'Somatória') {
          await saveResponse(alternativesModel.value)
        }
      }
      updateQuestionView(auxQuestionNumber, questionIndex)

      if (questionIndex) currentQuestionId.value = questionCache[questionIndex].questionId
      else currentQuestionId.value = questionCache.find((qc) => qc.order === auxQuestionNumber)!.questionId

      skippingQuestions.value = false
    }

    const checkIsDoubt = async () => {
      isLoadingDoubt.value = true
      try {
        const { data: answer } = await listsService.get<ResponseDTO<SingleTryType>>('/tries/single', { params: { tryId, listId: id }, signal: controller.signal })
        isDoubt.value = answer.data.userResponses.find((it) => it.questionId === currentQuestionId.value)?.isDoubt ?? false
      } finally {
        isLoadingDoubt.value = false
      }
    }

    const reachedDeadline = () => {
      if (listInfos?.value?.config && listInfos?.value?.config?.endDate) {
        const endDate = listInfos?.value?.config?.endDate;

        return new Date() >= new Date(endDate);
      } else if (listTry.value?.expiresAt) {
        const expiracyDate = listTry.value?.expiresAt

        return new Date() >= new Date(expiracyDate);
      }

      return false;
    }

    const isTimeUp = () => {
      if (
        listInfos?.value?.config
        && listInfos?.value?.config?.countdown
        && listTry?.value?.duration
      ) {
        const countdown = listInfos?.value?.config?.countdown;
        const duration = listTry?.value?.duration;

        return countdown <= duration / 60000;
      }

      return false;
    }

    const displayTimeIsUpModal = async () => {
      modalStatus.alertListTimeEnd = true;
    }

    const userCanAnswerList = async () => {
      if (isTimeUp()) {
        await displayTimeIsUpModal();

        if (alternativesModel.value) {
          await saveResponse(alternativesModel.value);
        }
      }

      if (reachedDeadline()) {
        modalStatus.alertListDeadlineEnd = true;

        if (alternativesModel.value) {
          await saveResponse(alternativesModel.value);
        }
      }
    }

    onMounted( async() => {
      await getRelatedQuestions();
      await initAnswerPage();
      await isMainCtaDisabled();
      userCanAnswerList();
    })

    const getListTry = async () => {
      const { data: answer } = await listsService.get<ResponseDTO<SingleTryType>>('/tries/single', { params: { tryId, listId: id }, signal: controller.signal })
      listTimeElapsed.value = answer.data.duration

      listTry.value = answer.data;
      let responses = 0;

      listTry.value.userResponses.map((res) => {
        if (res.responseHasAlternatives && res.responseHasAlternatives.length) {
          responses++;
        }
      });

      return answer;
    }

    const initAnswerPage = async (loadSpecificQuestion?: boolean) => {
      let questionsOrder: Array<{ questionId: number; order: number }> = []
      const { data } = await listsService.get<ResponseDTO<GetOneListResponseDTO>>('/id', { params: { listId: id }, signal: controller.signal })

      showFinishFeedback.value = data.data.returnFeedback
      listInfos.value = data.data

      questionsOrder = data.data.listHasQuestions

      let goToQuestionIndex = 0
      if (loadSpecificQuestion) {
        const currentSeries = Math.trunc(data.data.totalQuestions / 15) - 1

        goToQuestionIndex = currentSeries * 15
      }

      const goToQuestionId = data.data.listHasQuestions[goToQuestionIndex].questionId

      if (data.data.tags && data.data.tags.length > 0 && countAreas.value === 0) {
        data.data.tags.forEach((it) => {
          if (it.key === ListTagKeyEnum.AREA) {
            listLargeAreas.value.areas.push(it.value)
            countAreas.value++
            isListEnemModule.value = true
          } else if (it.value === 'no seu ritmo') isListEnemModuleAndAtYourPace.value = true
        })

        listLargeAreas.value.isListEnemModuleAndAtYourPace = isListEnemModuleAndAtYourPace.value
      }

      const answer = await getListTry();

      questionsOrder.forEach((q) => {
        const targetQuestion = questionsOrder.find((qo) => qo.questionId === q.questionId)

        if (targetQuestion) {
          const questionUserResponses = getAnswersOnInit(q.questionId, answer.data.userResponses)

          questionCache.push({
            questionId: q.questionId,
            order: questionsOrder.indexOf(targetQuestion) + 1,
            userResponses: questionUserResponses.alternativeIds ?? [],
            isAnswered: !!questionUserResponses?.alternativeIds?.length,
            isDoubt: questionUserResponses.isDoubt ?? false,
            discardedAlternatives: questionUserResponses.discardedAlternatives ?? [],
          })
        }
      })

      questionsNotAnswered.value = questionCache.map((qc) => ({ order: qc.order, questionId: qc.questionId, isDoubt: qc.isDoubt, isAnswered: qc.isAnswered }))

      const promises = Array<Promise<any>>()
      if (isListEnemModuleAndAtYourPace.value) {
        const iterationsAmount = questionCache.length / 15

        for (let i = 0; i < iterationsAmount; i++) promises.push(questionsService.get<ResponseDTO<QuestionDTO>>(`/${id}/${questionsNotAnswered.value[i * 15].questionId}`))
      }

      let auxQuestionNumber = -1
      let auxQuestionId = -1
      if (loadSpecificQuestion) {
        auxQuestionNumber = goToQuestionIndex
        auxQuestionId = goToQuestionId
      } else {
        for (let i = questionCache.length - 1; i >= 0; i--) {
          if (questionCache[i].isAnswered) {
            if (i !== questionCache.length - 1) {
              auxQuestionNumber = questionCache[i + 1].order
              auxQuestionId = questionCache[i + 1].questionId
            } else {
              auxQuestionNumber = questionCache[i].order
              auxQuestionId = questionCache[i].questionId
            }

            break
          }

          if (i === 0) {
            auxQuestionNumber = questionCache[i].order
            auxQuestionId = questionCache[i].questionId
          }
        }
      }
      updateQuestionView(loadSpecificQuestion ? ++auxQuestionNumber : auxQuestionNumber)
      currentQuestionId.value = auxQuestionId

      isDoubt.value = answer.data.userResponses.find((it) => it.questionId === currentQuestionId.value)?.isDoubt ?? false

      checkResponse(currentQuestionId.value)
      let dontShowMessageAgain = localStorage.getItem('dontShowAgain') === 'true'
      if (isListEnemModuleAndAtYourPace.value && !dontShowMessageAgain && questionNumber.value === 1) initalConfirmationMessages()

      const responses = await Promise.allSettled(promises)

      responses.forEach((res: any) => {
        const findQuestion = questionsNotAnswered.value.find((qna) => qna.questionId === res.value.data.data.id)

        if (findQuestion) {
          findQuestion.questionBelongingArea = res.value.data.data.tags.find((tag: TagDTO) => tag.key === ListTagKeyEnum.AREA)?.value

          const intervalStart = questionsNotAnswered.value.indexOf(findQuestion) % questionsNotAnswered.value.length
          const intervalEnd = intervalStart + 15

          for (let i = intervalStart; i < intervalEnd; i++) {
            if (questionsNotAnswered.value[i]) questionsNotAnswered.value[i].questionBelongingArea = findQuestion.questionBelongingArea
          }
        }
      })

      if (listInfos.value.config?.countdown) {
        exitListButton.label = 'Sair da lista'
        exitListButton.takeBreak = false;
      } else {
        exitListButton.label = 'Fazer uma pausa'
        exitListButton.takeBreak = true;
      }

      await getRelatedQuestions();
    }

    const handleExitListButtonAction = () => {
      if (isUserCheckingQuestions.value) {
        isUserCheckingQuestions.value = !isUserCheckingQuestions.value
        exitListButton.label = listInfos.value.config?.countdown ? 'Sair da lista' : 'Fazer uma pausa'
        return;
      }

      if (exitListButton.takeBreak) {
        triggerModal('ModalWarningListBreak', true)
        return;
      }

      triggerModal('ModalWarningListClose', true)
    }

    const getQuestionType = async (type: string) => (questionType.value = type)

    onUnmounted(() => {
      document.removeEventListener('keydown', close)
      controller.abort()
    })

    const handleClickChevron = () => finalButtonsRef.value?.scrollIntoView({ behavior: 'smooth' })

    const handleChooseUnansweredQuestion = async (questionNumberNotAnswered: number, questionId: number) => {
      if (alternativesModel.value) await saveResponse(alternativesModel.value)

      currentQuestionId.value = questionId
      questionNumberInput.value = questionNumberNotAnswered.toString()
      questionNumber.value = questionNumberNotAnswered

      modalStatus.forgottenQuestions = false
    }
    provide('handleChooseUnansweredQuestion', handleChooseUnansweredQuestion)

    const close = (e: Event) => {
      if (e instanceof KeyboardEvent) if (modalStatus.congrats && e.key === 'Escape') closeModal()
    }

    const showCheckQuestionsComponent = async () => {
      if (mainCtaDisabled.value) return;

      relatedQuestions.value = await getRelatedQuestions();
      if (alternativesModel.value) await saveResponse(alternativesModel.value)

      if(isListEnemModuleAndAtYourPace.value) {
        nextQuestionConfirmationMessages()
        return;
      }

      isUserCheckingQuestions.value = true;
      exitListButton.label = 'Voltar para as questões';
    }

    const closeModal = () => {
      modalStatus.congrats = false;
      if (listInfos.value.isTest) router.push('/banco-de-provas')
      else router.push('/listas')
    }

    document.addEventListener('keydown', close)

    const handleObjectQuestionsNotAnswered = (questionId: number, isDoubt?: boolean) => {
      const findQuestion = questionCache.find((qc) => qc.questionId === questionId)

      if (findQuestion) {
        const findQuestionNotAnswered = questionsNotAnswered.value.find((qna) => qna.questionId === findQuestion.questionId)

        if (findQuestionNotAnswered) {
          if (isDoubt !== undefined) findQuestionNotAnswered.isDoubt = isDoubt
          if (findQuestion.isAnswered) findQuestionNotAnswered.isAnswered = true
          else findQuestionNotAnswered.isAnswered = false
        }
      }
    }

    const previousQuestion = (questionOrder: number) => {
      let previousQuestionIndex = null
      let index = -1

      if (isListEnemModuleAndAtYourPace.value && questionOrder !== 0) index = questionCache.findIndex((qc) => qc.questionId === currentQuestionId.value)
      if (index !== -1) previousQuestionIndex = index - 1

      changeQuestion(questionOrder - 1, previousQuestionIndex)
    }

    const nextQuestion = (questionOrder: number) => {
      let nextQuestionIndex = null
      let index = -1

      if (isListEnemModuleAndAtYourPace.value && questionOrder <= 15) index = questionCache.findIndex((qc) => qc.questionId === currentQuestionId.value)
      if (index !== -1) nextQuestionIndex = index + 1

      changeQuestion(questionOrder + 1, nextQuestionIndex)
    }

    const onEnterKey = (questionOrder: string) => changeQuestion(Number(questionOrder))

    const checkResponse = (questionId: number) => {
      const findQuestion = questionCache.find((qc) => qc.questionId === questionId)

      if (findQuestion && (findQuestion.isAnswered || findQuestion.discardedAlternatives?.length)) {
        alternativesModel.value = {
          questionId: findQuestion.questionId,
          alternatives: findQuestion.userResponses,
          discardedAlternatives: findQuestion.discardedAlternatives
        }
      } else {
        alternativesModel.value = { questionId, alternatives: [], discardedAlternatives: [] }
      }
    }

    const triggerModal = (modalName: string, status = true) => {
      switch (modalName) {
        case 'congrats':
          modalStatus.congrats = status;
          return;

        case 'forgottenQuestions':
          modalStatus.forgottenQuestions = status;
          return;

        case 'ModalWarningListClose':
          modalStatus.warningListClose = status;
          return;

        case 'ModalWarningListBreak':
          modalStatus.warningListBreak = status;
          return;

        case 'AlertListIncomplete':
          modalStatus.alertListIncomplete = status;
          return;

        case 'AlertListTimeEnd':
          modalStatus.alertListTimeEnd = status;
          return;

        case 'AlertListDeadlineEnd':
          modalStatus.alertListDeadlineEnd = status;
          return;

        default:
          return;
      }
    }

    const handleListBreak = async () => {
      triggerModal('ModalWarningListBreak', false);
      if (!listInfos.value.instantFeedback && alternativesModel.value) {
        await saveResponse(alternativesModel.value)
      }

      isListEnemModule.value ? router.push('/listas') : router.go(-1)
    }

    const handleListClose = async () => {
      triggerModal('ModalWarningListClose', false)
      if (!listInfos.value.instantFeedback && alternativesModel.value) {
        await saveResponse(alternativesModel.value)
      }

      isListEnemModule.value ? router.push('/listas') : router.go(-1)
    }

    const handleListIncomplete = async () => {
      triggerModal('AlertListIncomplete', false);
    }

    const handleListTimeEnd = async () => {
      triggerModal('AlertListTimeEnd', false);
      window.location.href = localStorage.getItem('lastPage') || '/';
    }

    const handleListDeadlineEnd = async () => {
      triggerModal('AlertListDeadlineEnd', false);
      window.location.href = localStorage.getItem('lastPage') || '/';
    }

    const finalizeList = async (skipConfirmation?: boolean) => {
      if (mainCtaDisabled.value) return;

      if (alternativesModel.value) {
        updateCache(
          currentQuestionId.value,
          toRaw(alternativesModel.value.alternatives),
          toRaw(alternativesModel.value.discardedAlternatives)
        )
      }

      handleObjectQuestionsNotAnswered(currentQuestionId.value)
      handleFinalizeTry(skipConfirmation);
    }

    const handleFinalizeTry = async (skipConfirmation?: boolean) => {
      if (isListEnemModuleAndAtYourPace.value && !skipConfirmation) {
        nextQuestionConfirmationMessages()
        return
      }

      await listsService.patch('/tries/finish-try', undefined, { params: { tryId: Number(tryId) } })

      if (listInfos.value.returnFeedback || listInfos.value.config?.showResult) {
        router.push(`/gabarito/${id}/${tryId}`)
        return
      }

      router.push('/listas-compartilhadas')
    }

    const saveResponse = async (model: AlternativesSelectorModelValueType) => {
      const data = new CreateUpdateAnswerDTO(model, parseInt(tryId as string), isDoubt.value)
      const { data: correctAlternative } = await questionsService.put('/', data)

      correctAlternatives.value = correctAlternative.data.correctAlternativeIds

      await getListTry()

      await getRelatedQuestions()
      const total = await questionsAnswered()
      await setTotalResponses(total)
      await isMainCtaDisabled()
    }

    const getAreaName = (questionTags: TagDTO[]) => (areaName.value = questionTags.find((it) => it.key === 'area')?.value ?? '')
    const stopLoading = (stopLoading: boolean) => (isLoadingQuestion.value = stopLoading)

    const isUserCheckingQuestions = ref(false);

    const relatedQuestions = ref(Array<listHasQuestionDTO>())

    const getRelatedQuestions = async(): Promise<listHasQuestionDTO[]> => {
      if (!listTry?.value?.userResponses || !listInfos.value.listHasQuestions) {
        return [];
      }

      const questionsInformations = ref(Array<listHasQuestionDTO>());
      listInfos.value.listHasQuestions.forEach(element => {
        questionsInformations.value.push(element);
      });

      const questionsAnswers = ref(Array<UserResponseDTO>());
      listTry?.value?.userResponses.forEach(element => {
        questionsAnswers.value.push(element);
      });

      const mergedArray: listHasQuestionDTO[] = [];

      questionsInformations.value.forEach(obj1 => {
        const matchingObj = questionsAnswers.value.find(obj2 => obj2.questionId === obj1.questionId);

        if (matchingObj) {
          const mergedObj = { ...obj1, ...matchingObj };
          mergedArray.push(mergedObj);
        } else {
          mergedArray.push(obj1);
        }
      });

      relatedQuestions.value = mergedArray;

      return mergedArray;
    };

    const isSharedList = () => {
      return localStorage.getItem('lastPage') === '/listas-compartilhadas'
    };

    const setTotalResponses = async (total: number) => {
      totalResponses.value = total;
      progress.value = listTry.value ? total / listTry.value.totalQuestions : 0;
    }

    const questionsAnswered = async () => {
      const total = relatedQuestions.value.filter((question) => question.responseHasAlternatives?.some((rha) => rha.alternativeId !== null)).length;
      await setTotalResponses(total);
      return total;
    }

    const notFoundQuestionsCount = ref(0);
    const notFoundQuestionsIds = ref<string[]>([]);

    const incrementNotFoundQuestions = async (questionId: string) => {
      if (notFoundQuestionsIds.value.includes(questionId)) {
        return;
      }

      notFoundQuestionsIds.value.push(questionId);
      notFoundQuestionsCount.value++;
      await isMainCtaDisabled();
    }

    const isMainCtaDisabled = async () => {
      const answered = await questionsAnswered();
      mainCtaDisabled.value = isSharedList() && answered < (listInfos.value.totalQuestions - notFoundQuestionsCount.value);
    }

    const startTime = () => {
      return (listInfos?.value?.config?.countdown || 0)
    };

    watch(currentQuestionId, async (curId, prevId) => {
      checkResponse(curId)
      await checkIsDoubt()
      if (prevId) handleObjectQuestionsNotAnswered(prevId)

      if (isListEnemModuleAndAtYourPace.value) {
        const index = questionCache.findIndex((qc) => qc.questionId === curId)

        if (index === questionCache.length - 1) lastQuestion.value = true
        else lastQuestion.value = false
      }
      
    })

    watch(alternativesModel, async (model) => {
      await getRelatedQuestions();

      questionCache.forEach((qc) => {
        checkResponsesAlternativesModel.value.push({
          questionId: qc.questionId,
          alternatives: qc.userResponses,
          discardedAlternatives: qc.discardedAlternatives
        });
      });

      if (model) {
        checkResponsesAlternativesModel.value.forEach((item) => {
          if (item.questionId === model.questionId) {
            item.alternatives = model.alternatives;
            item.discardedAlternatives = model.discardedAlternatives;
          }
        })
      }

      if (model?.alternatives.length || model?.discardedAlternatives?.length) {
        saveResponse(model);
      }

      correctAlternatives.value = []
    })

    return {
      id,
      listId,
      listTryDTO,
      getQuestionType,
      correctAlternatives,
      listInfos,
      exitListButton,
      currentSerie,
      currentQuestionId,
      skippingQuestions,
      questionNumber,
      saveResponse,
      questionNumberInput,
      listTimeElapsed,
      listLargeAreas,
      questionsNotAnswered,
      handleClickChevron,
      previousQuestion,
      seriesCount,
      finalizeList,
      nextQuestion,
      finalButtonsRef,
      onEnterKey,
      alternativesModel,
      topicTag,
      handleFinalizeTry,
      modalStatus,
      closeModal,
      triggerModal,
      handleListClose,
      handleListBreak,
      handleListIncomplete,
      handleListTimeEnd,
      handleListDeadlineEnd,
      showFinishFeedback,
      isListEnemModule,
      areaName,
      isDoubt,
      isDoubtLabelButton,
      handleMarkAsDoubt,
      openMultipleActionsModal,
      messagesOnModal,
      isListEnemModuleAndAtYourPace,
      getAreaName,
      isLoadingDoubt,
      stopLoading,
      isLoadingQuestion,
      lastQuestion,
      tryId,
      listTry,
      progress,
      totalResponses,
      changeQuestion,
      showCheckQuestionsComponent,
      isUserCheckingQuestions,
      relatedQuestions,
      checkResponsesAlternativesModel,
      handleSaveQuestion,
      isSharedList,
      mainCtaDisabled,
      startTime,
      handleExitListButtonAction,
      getRelatedQuestions,
      displayTimeIsUpModal,
      isMobile: screen.isMobile,
      AlertIconRedIcon,
      AlertIcon,
      StopwatchClockIcon,
      incrementNotFoundQuestions,
      DefaultTheme,
    }
  }
})
