import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83a1f234"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "doodle"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCloseIcon = _resolveComponent("XCloseIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-backdrop", {'higher-z-index': _ctx.context.name === 'clearFilters' ?? true}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
          'inline-modal': _ctx.context.isDoodleModal,
          'modal-wrapper': true,
          'fit': _ctx.context.variant === 'fit'
        })
          }, [
            (_ctx.context.isDoodleModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.context.doodle)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.context.doodle,
                        alt: ""
                      }, null, 8, _hoisted_2))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["modal", {
            'fit': _ctx.context.variant === 'fit',
            'filter-list': _ctx.context.name === 'filterList' ?? true,
            'clear-filters': _ctx.context.name === 'clearFilters' ?? true,
            'print-list': _ctx.context.name === 'printList' ?? true,
            'limit-time': _ctx.context.name === 'limitTime' ?? true,
            'time-out': _ctx.context.name === 'timeOut' ?? true,
            'quit-warning-modal': _ctx.context.name === 'quitWarning',
            'doodle-modal': _ctx.context.isDoodleModal,
            'list-break': _ctx.context.name === 'listBreak',
          }]),
              "aria-labelledby": "modalTitle",
              "aria-describedby": "modalDescription"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["modal-header", {
            borderless: Boolean(_ctx.context.icon || _ctx.context.isBorderless),
            fit: _ctx.context.variant === 'fit',
          }])
              }, [
                (_ctx.context.title)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.context.title), 1))
                  : _createCommentVNode("", true),
                (_ctx.context.icon)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.context.icon), _normalizeProps(_mergeProps({ key: 1 }, _ctx.context.iconProps)), null, 16))
                  : _createCommentVNode("", true),
                (!_ctx.context.isDoodleModal)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "div-close cursor-pointer",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                    }, [
                      _createVNode(_component_XCloseIcon, {
                        stroke: _ctx.DefaultTheme().colors.secondaryGray
                      }, null, 8, ["stroke"])
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["modal-content", {
            borderless: Boolean(_ctx.context.icon || _ctx.context.isBorderless),
            fit: _ctx.context.variant === 'fit'
          }])
              }, [
                (_ctx.context.isLoaderModal && _ctx.context.loaderGIF)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.context.loaderGIF,
                      alt: ""
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "description", {}, undefined, true),
                _renderSlot(_ctx.$slots, "filter-status", {}, undefined, true),
                _renderSlot(_ctx.$slots, "filter-answers-available", {}, undefined, true),
                _renderSlot(_ctx.$slots, "filter-list-creator", {}, undefined, true)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["modal-footer", {
            borderless: Boolean(_ctx.context.icon || _ctx.context.isBorderless),
            fit: _ctx.context.variant === 'fit'
          }])
              }, [
                _renderSlot(_ctx.$slots, "left-container", {}, undefined, true),
                _renderSlot(_ctx.$slots, "right-container", {}, undefined, true),
                _renderSlot(_ctx.$slots, "footer-actions-container", {}, undefined, true)
              ], 2)
            ], 2)
          ], 2)
        ], 2)
      ])
    ]),
    _: 3
  }))
}